import { configureStore } from "@reduxjs/toolkit";
import musicReduce from "./features/music/musicSlice"
import authReducer from "./features/auth/authSlice"
import btnReducer from "./features/presale/preslaeSlice"
import searchReducer from "./features/music/searchSlice"
import createSongReducer from "./features/music/createSlice"

const store = configureStore({
    reducer: {
        music: musicReduce,
        auth: authReducer,
        presale: btnReducer,
        search: searchReducer,
        create: createSongReducer
    }
})

export default store