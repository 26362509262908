import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"

import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
} from "@/components/ui/form"
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSlot,
} from "@/components/ui/input-otp"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { getUserDetails, SET_OTPCARD, verifyOtp } from "@/redux/features/auth/authSlice"
import { useEffect, useState } from "react"
import toast from "react-hot-toast"
import axios from "axios"
import { IoMdArrowRoundBack } from "react-icons/io";
import { Button } from "@/components/ui/button"
import { TabsContent } from "@/components/ui/tabs"
import { useNavigate } from "react-router-dom"

const FormSchema = z.object({
    otp: z.string().min(4, {
        message: "Your one-time password must be 6 characters.",
    }),
})

const OtpForm = ({ tabName }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [timeLeft, setTimeLeft] = useState(60);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    // otp form
    const form = useForm({
        resolver: zodResolver(FormSchema),
        defaultValues: {
            otp: "",
        },
    })
    const { reset: resetOtp } = form;

    // otp submit
    const handleOtpSubmit = async (data) => {
        await dispatch(verifyOtp(data)).unwrap()
        await dispatch(getUserDetails()).unwrap()
        navigate("/creations")
        resetOtp()
    }

    // resend OTP
    const handleResend = async () => {
        const otpToken = sessionStorage.getItem('otpToken')
        try {
            toast.loading("Resending OTP", { id: "token" })
            await axios.post("/sendotp", {}, { headers: { Authorization: `Bearer ${otpToken}` } })
            toast.success("OTP Sent", { id: "token" })
            setTimeLeft(60)
            setIsButtonDisabled(true)
        } catch (error) {
            console.log(error);
            toast.error("Error Sending OTP", { id: "token" })
        }
    }

    const handleBack = () => {
        sessionStorage.removeItem('otpToken')
        dispatch(SET_OTPCARD(false))
    }

    useEffect(() => {
        if (timeLeft === 0) {
            setIsButtonDisabled(false);
            return;
        }
        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft => timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);

    }, [timeLeft]);

    return (
        <TabsContent value={tabName}>
            <Form {...form}>
                <form onSubmit={form.handleSubmit(handleOtpSubmit)} className="relative w-full bg-slate-800 rounded-lg p-4 flex gap-4 items-center justify-center flex-col">
                    <button onClick={handleBack} className="absolute rounded-full p-1 top-4 left-4 bg-green-400 hover:bg-green-500 text-black " type="button"><IoMdArrowRoundBack size={24} /></button>
                    <FormField
                        control={form.control}
                        name="otp"
                        render={({ field }) => (
                            <FormItem className="flex items-center justify-center flex-col mt-4" >
                                <FormLabel className="text-green-400" >One-Time Password</FormLabel>
                                <FormControl>
                                    <InputOTP maxLength={4} {...field}>
                                        <InputOTPGroup className="text-white" >
                                            <InputOTPSlot className="border-slate-400 ring-green-400" index={0} />
                                            <InputOTPSlot className="border-slate-400 ring-green-400" index={1} />
                                            <InputOTPSlot className="border-slate-400 ring-green-400" index={2} />
                                            <InputOTPSlot className="border-slate-400 ring-green-400" index={3} />
                                        </InputOTPGroup>
                                    </InputOTP>
                                </FormControl>
                                <FormDescription className="text-slate-300" >
                                    Please enter the one-time password sent to your mail address.
                                </FormDescription>
                                <FormMessage />
                            </FormItem>
                        )}
                    />
                    <div className="flex items-center gap-2 w-full" >
                        <Button className="w-full bg-green-400 hover:bg-green-500 text-black " type="submit">Submit</Button>
                        <Button disabled={isButtonDisabled} onClick={handleResend} className="w-full bg-green-400 hover:bg-green-500 text-black " type="button">{isButtonDisabled ? `Resend in : ${timeLeft}` : "Resend OTP"}</Button>
                    </div>
                </form>
            </Form>
        </TabsContent>
    )
}

export default OtpForm