import { useEffect, useRef, useCallback, useState } from 'react';
import ReactHowler from 'react-howler';
import raf from "raf"
import { useDispatch, useSelector } from 'react-redux';
import { ADD_LIKESONG, REMOVE_LIKESONG, SET_HOMEMUSIC, SET_LOADED, SET_MOBILEPLAYER, SET_PLAYING, SET_PLAYINGFROM, SET_PLAYLOADING, SET_SHOWVIEWSONG, SET_SONG, SET_VIEWSONG, SET_VIEWSONGFROM } from '@/redux/features/music/musicSlice';
import { IoPlay } from "react-icons/io5";
import { FaPause, FaVolumeXmark } from "react-icons/fa6";
import { RxLoop } from "react-icons/rx";
import { IoIosVolumeHigh, IoIosShareAlt, IoIosArrowDown, IoIosHeart, IoIosHeartEmpty } from "react-icons/io";
import { MdSkipNext, MdSkipPrevious } from "react-icons/md";
import { TbDownload } from "react-icons/tb";
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import Lyrics from './Lyrics';
import barsAni from "../../assets/soundbars.json"
import Lottie from 'lottie-react';
import { ThreeCircles } from 'react-loader-spinner';
import { STOP_LIVESONG } from '@/redux/features/music/createSlice';

const MusicPlayer = ({ bgPlayer }) => {

    const dispatch = useDispatch()
    const playerRef = useRef(null);
    const rafId = useRef(null);
    const [wasPlayingBeforeSeek, setWasPlayingBeforeSeek] = useState(false);
    const { homeMusic, song, mobilePlayer, playing, playLoading, playingFrom, discoverSongs, userSongs, likedSongs, viewSong, showViewSong, viewSongFrom } = useSelector(state => state.music)
    const { searchSongs } = useSelector(state => state.search)
    const { user } = useSelector(state => state.auth)
    const [seek, setSeek] = useState(0)
    const [duration, setDuration] = useState(0)
    const [volume, setVolume] = useState(1.0)
    const [loop, setLoop] = useState(false)
    const [mute, setMute] = useState(false)
    const [isSeeking, setIsSeeking] = useState(false)
    const location = useLocation()
    const playCountTimeout = useRef(null);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    const [liked, setLiked] = useState(false)
    const [likedLoading, setLikedLoading] = useState(false)
    const [downloadLoading, setdownloadLoading] = useState(false)
    const animateRef = useRef()
    // Minimum swipe distance, adjust accordingly
    const minSwipeDistance = 50;

    const progressPercent = (seek / duration) * 100;
    const volumePercent = (volume / 1) * 100;

    const handleToggle = (e) => {
        e.stopPropagation()
        dispatch(SET_PLAYING(!playing));
    }

    const handleOnLoad = () => {
        dispatch(SET_LOADED(true))
        dispatch(SET_PLAYLOADING(false))
        setSeek(0)
        setDuration(playerRef.current.duration())
        handlePlayCount(song)
    };

    const handleOnPlay = () => {
        if (homeMusic) {
            dispatch(SET_HOMEMUSIC(false))
        }
        dispatch(SET_PLAYING(true))
        dispatch(STOP_LIVESONG())
        renderSeekPos();
    };

    const handleOnEnd = () => {
        dispatch(SET_PLAYING(false))
        clearRAF();
        // dispatch(SET_SONG(null))
        handlePlayCountOnEnd(song)
    };

    // const handleStop = () => {
    //     playerRef.current.stop();
    //     dispatch(SET_PLAYING(false))
    //     renderSeekPos();
    // };

    const handleLoopToggle = (e) => {
        e.stopPropagation()
        setLoop(!loop);
    }
    const handleMuteToggle = (e) => {
        e.stopPropagation()
        setMute(!mute);
    }
    const handleMouseDownSeek = (e) => {
        e.stopPropagation()
        setWasPlayingBeforeSeek(playing)
        if (playing) {
            dispatch(SET_PLAYING(false))
        }
        setIsSeeking(true)
    }

    const handleMouseUpSeek = (e) => {
        e.stopPropagation()
        setIsSeeking(false)
        const newSeek = parseFloat(e.target.value);
        playerRef.current.seek(newSeek);
        setSeek(newSeek)
        if (wasPlayingBeforeSeek) {
            dispatch(SET_PLAYING(true))
        }
    };

    const handleSeekingChange = (e) => {
        e.stopPropagation()
        const newSeek = parseFloat(e.target.value);
        setSeek(newSeek)
        if (isSeeking) {
            playerRef.current.seek(newSeek); // Seek as the slider moves, if needed
        }
    }

    const renderSeekPos = useCallback(() => {
        if (!isSeeking) {
            const currentSeek = playerRef.current?.seek();
            if (currentSeek !== seek) { // Avoid unnecessary state updates
                setSeek(currentSeek)
            }
        }
        if (playing && !isSeeking) {
            rafId.current = raf(renderSeekPos);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSeeking, playing])

    useEffect(() => {
        if (playing) {
            renderSeekPos();
        }
        return () => {
            clearRAF();
        };
    }, [playing, renderSeekPos]);


    // const handleRate = (e) => {
    //     const rateValue = parseFloat(e.target.value);
    //     playerRef.current.rate(rateValue);
    //     dispatch(SET_RATE(rateValue))
    // };

    const clearRAF = () => {
        raf.cancel(rafId.current);
    };

    // Function to format seconds to "mm:ss"
    function formatTime(seconds) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const formattedMinutes = minutes.toString();
        const formattedSeconds = remainingSeconds.toString().padStart(2, '0');
        return `${formattedMinutes}:${formattedSeconds}`;
    }

    // change song
    const changeSong = (songs, direction) => {
        if (!songs) return;
        const index = songs.findIndex(dsong => dsong?.song_id === song?.song_id);
        const nextIndex = index + direction;
        if (nextIndex < 0 || nextIndex >= songs.length) return;
        dispatch(SET_SONG(songs[nextIndex]));
        dispatch(SET_PLAYING(true));
        dispatch(SET_PLAYLOADING(true));
    };

    // Next song
    const handleNext = (e) => {
        e.stopPropagation()
        if (playingFrom === "discoverSongs") {
            changeSong(discoverSongs, 1)
        } else if (playingFrom === "userSongs") {
            changeSong(userSongs, 1)
        } else if (playingFrom === "likedSongs") {
            changeSong(likedSongs, 1)
        } else if (playingFrom === "search") {
            changeSong(searchSongs, 1)
        }
    }
    // prev song
    const handlePrev = (e) => {
        e.stopPropagation()
        if (playingFrom === "discoverSongs") {
            changeSong(discoverSongs, -1)
        } else if (playingFrom === "userSongs") {
            changeSong(userSongs, -1)
        } else if (playingFrom === "likedSongs") {
            changeSong(likedSongs, -1)
        } else if (playingFrom === "search") {
            changeSong(searchSongs, -1)
        }
    }

    // handle Play count
    async function handlePlayCount(song) {
        const lastPlayed = sessionStorage.getItem(`lastPlayed-${song?.song_id}`);
        const now = new Date().getTime();
        const minInterval = 300000;
        const playDurationRequired = 30000;

        // Clear existing timeout to prevent multiple calls if the song changes quickly
        if (playCountTimeout.current) {
            clearTimeout(playCountTimeout.current);
        }

        // Check if the song was played more than 5 minutes ago
        if (!lastPlayed || now - parseInt(lastPlayed) > minInterval) {
            playCountTimeout.current = setTimeout(async () => {
                try {
                    await axios.post("/view", { song_id: song?.song_id });
                    sessionStorage.setItem(`lastPlayed-${song?.song_id}`, now.toString());
                } catch (error) {
                    console.error('Failed to update play count:', error);
                }
            }, playDurationRequired);
        }
    }

    // handle play count when the song ends
    async function handlePlayCountOnEnd(song) {
        const lastPlayed = sessionStorage.getItem(`lastPlayed-${song?.song_id}`);
        const now = new Date().getTime();
        const minInterval = 300000;

        if (!lastPlayed || now - parseInt(lastPlayed) > minInterval) {
            try {
                await axios.post("/view", { song_id: song?.song_id });
                sessionStorage.setItem(`lastPlayed-${song?.song_id}`, now.toString());
            } catch (error) {
                console.error('Failed to update play count:', error);
            }
        }
    }


    // copy song link
    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            console.log('URL copied to clipboard!');
            toast.success('URL copied to clipboard!', { id: "copyToast" })
        }).catch(err => {
            console.error('Failed to copy:', err);
        });
    };

    // handle share song
    const handleShare = async (song) => {
        const url = `https://song.soundofmeme.com/${song?.slug}`;
        const title = 'Check out this song!';
        // const text = song?.song_name + ":";

        if (navigator.share) {
            try {
                await navigator.share({
                    title,
                    // text,
                    url
                });
                console.log('Song shared successfully!');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            copyToClipboard(url)
        }
    };

    const onTouchStart = (e) => {
        setTouchStart(e.targetTouches[0].clientY);
    };

    const onTouchMove = (e) => {
        setTouchEnd(e.targetTouches[0].clientY);
    };

    const onTouchEnd = () => {
        if (touchStart && touchEnd) {
            const distance = touchEnd - touchStart;
            // Check if the swipe is a downward swipe and long enough
            if (distance > minSwipeDistance) {
                dispatch(SET_MOBILEPLAYER(false))
            }
        }
        // Reset values
        setTouchStart(null);
        setTouchEnd(null);
    };

    const handleMobilePlayer = () => {
        dispatch(SET_MOBILEPLAYER(true))
    }

    const handleLike = async (songId) => {
        const token = window.localStorage.getItem('token')
        if (!user || !token) {
            return toast.error("Login to like the song", { id: "likeSong" })
        }

        try {
            setLikedLoading(true)
            if (liked) {
                toast.loading("Removing from liked songs", { id: "likeSong" })
                await axios.post("/dislike",
                    { song_id: songId },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                toast.success("Like Removed", { id: "likeSong" })
                dispatch(REMOVE_LIKESONG(songId))
                setLikedLoading(false)
                setLiked(false)
            } else {
                toast.loading("Adding to liked songs", { id: "likeSong" })
                await axios.post("/like",
                    { song_id: songId },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                toast.success("Like Added", { id: "likeSong" })
                dispatch(ADD_LIKESONG(song))
                setLikedLoading(false)
                setLiked(true)
            }
        } catch (error) {
            console.log(error);
            setLikedLoading(false)
            toast.error("Error Occured", { id: "likeSong" })
        }
    }

    // download song
    const downloadSong = async (song) => {

        if (!user) {
            return toast.error('Login to download the song')
        }
        const token = window.localStorage.getItem('token')
        try {
            setdownloadLoading(true)
            toast.loading("Starting Download", { id: "download" })
            const response = await axios.get(`/download?song_id=${song?.song_id}`, { headers: { Authorization: `Bearer ${token}` } });
            const signedUrl = response.data.url;
            download(signedUrl);
            setdownloadLoading(false)
            toast.success("Download Started", { id: "download" })
        } catch (error) {
            setdownloadLoading(false)
            console.error('Error fetching signed URL:', error);
            toast.error('Error Occured. Please try again.', { id: "download" });
        }
    };

    function download(url) {
        const a = document.createElement('a');
        a.href = url;
        a.download = `${song?.song_name} - Sound of Meme`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    // play song from view that you are viewing
    const handleViewSongPlay = () => {
        dispatch(SET_PLAYING(true))
        dispatch(SET_PLAYLOADING(true))
        dispatch(SET_SONG(viewSong))
        dispatch(SET_VIEWSONG(null))
        dispatch(SET_SHOWVIEWSONG(false))
        dispatch(SET_PLAYINGFROM(viewSongFrom))
    }

    // Effect to clean up on component unmount or when the song changes
    useEffect(() => {
        return () => {
            if (playCountTimeout.current) {
                clearTimeout(playCountTimeout.current);
            }
        };
    }, [song]);

    // lottie animation ref
    useEffect(() => {
        if (playing && !playLoading) {
            animateRef.current?.play();
            animateRef.current?.setSpeed(1.8)
        } else {
            animateRef.current?.pause();
        }
    }, [playing, playLoading]);

    // add no scroll on body when mobile comopnent is active
    useEffect(() => {
        const body = document.body;
        if (mobilePlayer) {
            body.classList.add('no-scroll');
        } else {
            body.classList.remove('no-scroll');
        }

        return () => {
            body.classList.remove('no-scroll');
        };
    }, [mobilePlayer]);

    // Get liked status
    useEffect(() => {
        const token = window.localStorage.getItem('token')
        const getLikeStatus = async () => {
            try {
                setLikedLoading(true)
                const response = await axios.get(`/checklike?song_id=${song?.song_id}`, {
                    headers: { Authorization: `Bearer ${token}` }
                })
                setLiked(response?.data?.status)
                setLikedLoading(false)
            } catch (error) {
                console.log(error);
                setLikedLoading(false)
            }
        }
        if (user && song && token) {
            getLikeStatus()
        }
    }, [user, song])

    return (
        <>
            {!bgPlayer ? <div className='full-control text-white w-full flex items-center justify-center flex-col px-4'>
                <h1 className='text-3xl mt-4 font-bungee bg-gradient-to-r to-teal-400 from-green-400 font-potta bg-clip-text text-transparent text-center' >{song?.song_name}</h1>
                <ReactHowler
                    src={[song?.song_url]}
                    playing={playing}
                    onLoad={handleOnLoad}
                    onPlay={handleOnPlay}
                    onEnd={handleOnEnd}
                    loop={loop}
                    mute={mute}
                    volume={volume}
                    ref={playerRef}
                    html5={true}
                />
                <div className='w-full mt-8 mb-4 max-w-xl relative h-2 border rounded-full' >
                    <input
                        type='range'
                        min='0'
                        max={duration ? duration.toFixed(2) : 0}
                        step='.01'
                        value={seek}
                        onChange={handleSeekingChange}
                        onMouseDown={handleMouseDownSeek}
                        onMouseUp={handleMouseUpSeek}
                        onTouchStart={handleMouseDownSeek}
                        onTouchEnd={handleMouseUpSeek}
                        className='w-full opacity-0 appearance-none rounded-full h-5 cursor-pointer bg-transparent border border-slate-600 absolute -top-2 left-0 z-20 shadow-inner [&::-webkit-slider-thumb]:opacity-0 [&::-moz-range-thumb]:opacity-0'
                    />
                    <div style={{ width: `${progressPercent}%` }} className={`h-[6px] z-10 bg-gradient-to-r to-teal-400 from-green-400  absolute top-0 rounded-full left-0`} ></div>
                    <span className='absolute left-1 -top-5 text-xs' >{formatTime(Math.round(seek))}</span>
                    <span className='absolute right-1 -top-5 text-xs' >{(duration) ? formatTime(Math.round(duration)) : 'NaN'}</span>
                </div>
                <div className='w-full max-w-xl flex items-center justify-center gap-8' >
                    <button type='button' className={'relative z-10 border-2 p-2 rounded-full focus:outline-none' + (loop ? " bg-gradient-to-r from-teal-400 to-green-400 text-black " : "  ")} onClick={handleLoopToggle} ><RxLoop /></button>
                    <button type='button' className=' relative z-10 border-2 p-2 rounded-full focus:outline-none' onClick={handleToggle} >{!playing ? <IoPlay className='pl-0.5' size={32} /> : <FaPause size={32} />}</button>
                    <button type='button' className={' relative z-10 border-2 p-2 rounded-full focus:outline-none' + (mute ? " bg-gradient-to-r from-teal-400 to-green-400 text-black " : "  ")} onClick={handleMuteToggle} ><FaVolumeXmark size={16} /></button>
                </div>
                <div className='flex items-center justify-end gap-2 mt-4 ' >
                    <IoIosVolumeHigh />
                    <div className='relative h-1.5 border rounded-full w-24'>
                        <input
                            type='range'
                            min='0'
                            max='1'
                            step='.05'
                            value={volume}
                            onChange={e => setVolume(parseFloat(e.target.value))}
                            className='opacity-0 w-full appearance-none rounded-full h-3 cursor-pointer bg-transparent border border-slate-600 absolute -top-1 left-0 z-20 shadow-inner [&::-webkit-slider-thumb]:opacity-0 [&::-moz-range-thumb]:opacity-0'
                        />
                        <div style={{ width: `${volumePercent}%` }} className={`h-[4px] z-10 bg-gradient-to-r to-teal-400 from-green-400  absolute top-0 rounded-full left-0`} ></div>
                    </div>
                </div>
            </div> : song ?
                <div onClick={handleMobilePlayer} className={' select-none fixed md:bottom-0 left-0 right-0 w-full  bg-slate-900 ' + (location.pathname === "/about" ? " bottom-0 z-[999] " : " bottom-16 z-[99] ")} >
                    <div className='flex border-t-green-700 border-opacity-50 border-t py-2 px-4 md:py-0 justify-between items-center gap-8 lg:gap-16 relative' >
                        <div className='absolute -z-[9]' >
                            <ReactHowler
                                src={[song?.song_url]}
                                playing={playing}
                                onLoad={handleOnLoad}
                                onPlay={handleOnPlay}
                                onEnd={handleOnEnd}
                                loop={loop}
                                mute={mute}
                                volume={volume}
                                ref={playerRef}
                                html5={true}
                            />
                        </div>
                        <div className='flex gap-2 w-3/5 sm:w-auto md:w-1/4 overflow-hidden ' >
                            <img className=' w-10 md:w-16 aspect-square object-cover ' src={song?.image_url} alt='song cover' />
                            <div className=' md:hidden lg:flex flex-col justify-between w-full truncate'>
                                <p className='text-white truncate capitalize ' >{song?.song_name}</p>
                                <span className='text-slate-400 text-sm truncate' >{song?.username}</span>
                            </div>
                        </div>
                        <div className=' hidden md:flex flex-col flex-grow items-center py-4 gap-4 w-full' >
                            <div className='w-full max-w-xl flex items-center justify-center gap-8 lg:gap-10' >
                                <button type='button' className={'relative z-10 focus:outline-none' + (loop ? " text-green-400 " : " text-white hover:text-green-400 ")} onClick={handleLoopToggle} ><RxLoop size={16} /></button>
                                <button type='button' className={' relative z-10 focus:outline-none' + (mute ? " text-green-400 " : " text-white hover:text-green-400")} onClick={handleMuteToggle} ><FaVolumeXmark size={16} /></button>
                                <button type='button' className=' relative z-10 focus:outline-none text-green-400' onClick={handlePrev} ><MdSkipPrevious size={32} /></button>
                                <button type='button' className=' relative z-10 focus:outline-none text-green-400' onClick={handleToggle} >{!playing ? <IoPlay className='pl-0.5' size={32} /> : <FaPause size={32} />}</button>
                                <button type='button' className=' relative z-10 focus:outline-none text-green-400' onClick={handleNext} > <MdSkipNext size={32} /> </button>
                                <button disabled={likedLoading} type='button' className=' disabled:opacity-50 relative z-10 focus:outline-none text-white hover:text-green-400 '
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleLike(song?.song_id)
                                    }} > {liked ? <IoIosHeart className='text-green-400' size={20} /> : <IoIosHeartEmpty size={20} />}

                                </button>
                                <button type='button' className=' relative z-10 focus:outline-none text-white hover:text-green-400 '
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        handleShare(song)
                                    }} ><IoIosShareAlt size={22} />

                                </button>
                                <button disabled={downloadLoading} type='button' className=' disabled:opacity-50 relative z-10 focus:outline-none text-white hover:text-green-400'
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        downloadSong(song)
                                    }} >
                                    <TbDownload size={22} />
                                </button>
                            </div>
                            <div className='w-full max-w-xl relative border border-slate-500 rounded-full h-2' >
                                <input
                                    type='range'
                                    min='0'
                                    max={duration ? duration.toFixed(2) : 0}
                                    step='.01'
                                    value={seek}
                                    onClick={(e) => e.stopPropagation()}
                                    onChange={handleSeekingChange}
                                    onMouseDown={handleMouseDownSeek}
                                    onMouseUp={handleMouseUpSeek}
                                    onTouchStart={handleMouseDownSeek}
                                    onTouchEnd={handleMouseUpSeek}
                                    className='w-full appearance-none rounded-full h-4 cursor-pointer opacity-0 absolute -top-1 left-0 z-20 shadow-inner [&::-webkit-slider-thumb]:opacity-0 [&::-moz-range-thumb]:opacity-0'
                                />
                                <div style={{ width: `${progressPercent}%` }} className={`h-1.5 z-10 bg-gradient-to-r to-teal-400 from-green-400  absolute top-0 rounded-full left-0`} ></div>
                                <span className='absolute -left-8 -top-1.5 text-xs text-slate-400' >{formatTime(Math.round(seek))}</span>
                                <span className='absolute -right-8 -top-1.5 text-xs text-slate-400' >{(duration) ? formatTime(Math.round(duration)) : 'NaN'}</span>
                            </div>
                        </div>
                        <div className='hidden md:block md:w-1/4' >
                            <div className='flex items-center justify-end gap-2 text-green-400 ' >
                                <IoIosVolumeHigh size={24} onClick={(e) => e.stopPropagation()} />
                                <div className='relative border border-slate-400 rounded-full h-[5px] w-24'>
                                    <input
                                        type='range'
                                        min='0'
                                        max='1'
                                        step='.05'
                                        value={volume}
                                        onChange={e => {
                                            e.stopPropagation()
                                            setVolume(parseFloat(e.target.value))
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                        className=' w-full appearance-none rounded-full h-3 cursor-pointer opacity-0 border border-slate-600 absolute -top-1 left-0 z-20 shadow-inner [&::-webkit-slider-thumb]:opacity-0 [&::-moz-range-thumb]:opacity-0'
                                    />
                                    <div style={{ width: `${volumePercent}%` }} className={`h-1 z-10 bg-gradient-to-r to-teal-400 from-green-400  absolute top-0 rounded-full left-0`} ></div>
                                </div>
                            </div>
                        </div>
                        <div className='md:hidden flex items-center justify-center gap-3' >
                            {/* <button type='button' className=' relative z-10 focus:outline-none text-green-400' onClick={handlePrev} ><MdSkipPrevious size={32} /></button> */}
                            <button type='button' className=' relative z-10 focus:outline-none text-green-400' onClick={handleToggle} >{!playing ? <IoPlay className='pl-0.5' size={28} /> : <FaPause size={28} />}</button>
                            {/* <button type='button' className=' relative z-10 focus:outline-none text-green-400' onClick={handleNext} > <MdSkipNext size={32} /> </button> */}
                            <button disabled={likedLoading} type='button' className=' disabled:opacity-50  relative z-10 focus:outline-none text-white  '
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleLike(song?.song_id)
                                }} >
                                {liked ? <IoIosHeart className='text-green-400' size={24} /> : <IoIosHeartEmpty size={28} />}
                            </button>
                            <button type='button' className=' relative z-10 focus:outline-none text-white  '
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleShare(song)
                                }} ><IoIosShareAlt size={28} />
                            </button>
                        </div>
                        <div style={{ width: `${progressPercent}%` }} className={`md:hidden h-0.5 z-10 bg-gradient-to-r to-teal-400 from-green-400  absolute bottom-0 rounded-full left-0`} ></div>
                    </div>
                </div> : null}
            <div className={'fixed top-0 left-0 flex flex-col w-full min-h-screen max-h-screen py-4 md:px-6 backdrop-blur-xl bg-slate-950 z-[998] transition-all duration-300 ' + (mobilePlayer ? " top-[0%] overflow-y-auto " : "top-[120%]")} >
                <img className="select-none absolute opacity-25 top-0 left-0 w-full h-full object-cover -z-10 blur aspect-square" src={song?.image_url} alt={song?.song_name} />
                <div className=' px-6 md:px-0 max-w-screen-xl w-full mx-auto flex items-center gap-4 select-none justify-between mb-8 lg:mb-0' >
                    <button onClick={() => {
                        dispatch(SET_VIEWSONG(null))
                        dispatch(SET_SHOWVIEWSONG(false))
                        dispatch(SET_VIEWSONGFROM(null))
                        dispatch(SET_MOBILEPLAYER(false))
                    }} className='text-white p-2 flex-shrink-0' > <IoIosArrowDown size={32} /> </button>
                    <p className='text-white flex-grow text-center text-lg font-potta truncate' >Sound Of Meme</p>
                    <button type='button' className=' focus:outline-none text-white ' onClick={() => showViewSong ? handleShare(viewSong) : handleShare(song)} ><IoIosShareAlt size={32} /> </button>
                </div>
                <div className=' max-w-screen-xl w-full mx-auto flex flex-col md:flex-row md:justify-evenly items-center flex-grow gap-8 md:gap-16 md:pb-8' >
                    <div
                        className='max-w-md w-full px-6 md:px-0 '
                        onTouchStart={onTouchStart}
                        onTouchMove={onTouchMove}
                        onTouchEnd={onTouchEnd}>
                        <div className=' mt-8  w-full flex items-center justify-center max-w-sm lg:max-w-md mx-auto relative' >
                            <div className={`relative rounded-2xl  md:max-w-[280px] lg:max-w-[300px] w-[90%] ${playing && !playLoading && !showViewSong && " song-cover-shadow "}`} >
                                <img src={viewSong && showViewSong ? viewSong?.image_url : song?.image_url} alt='cover' className=' aspect-square object-cover w-full rounded-2xl' />
                                {playLoading && <div className='w-full h-full flex items-center justify-center absolute top-0 left-0 bg-slate-950/60 backdrop-blur rounded-2xl' >
                                    <ThreeCircles
                                        visible={true}
                                        height="100"
                                        width="100"
                                        color="#ffffff"
                                        ariaLabel="three-circles-loading"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                    />
                                </div>}
                            </div>
                        </div>
                        <div className={`flex items-start gap-4 ${showViewSong ? ' justify-center text-center mt-4 ' : ' justify-between mt-12 '} `}>
                            <div className=' truncate ' >
                                <p className=' truncate text-xl text-slate-200 font-poppins font-semibold capitalize' >{viewSong && showViewSong ? viewSong?.song_name : song?.song_name}</p>
                                <p className=' text-sm truncate text-slate-400 font-poppins font-medium capitalize' >{viewSong && showViewSong ? viewSong?.username : song?.username}</p>
                            </div>
                            {!showViewSong && <div className=' flex items-center gap-4 mt-1 ' >
                                <button type='button' className=' focus:outline-none text-white ' onClick={() => handleShare(song)} ><IoIosShareAlt size={28} /> </button>
                                <button disabled={likedLoading} type='button' className=' disabled:opacity-50 relative z-10 focus:outline-none text-white  ' onClick={() => handleLike(song?.song_id)} > {liked ? <IoIosHeart className='text-green-400' size={24} /> : <IoIosHeartEmpty size={24} />} </button>
                                <button disabled={downloadLoading} type='button' className=' disabled:opacity-50 relative z-10 focus:outline-none text-white hover:text-green-400' onClick={() => downloadSong(song)} ><TbDownload size={24} /> </button>
                            </div>}
                        </div>
                        {!showViewSong && <div className='mt-16 md:mt-20' >
                            <div className='w-full max-w-xl lg:max-w-full relative border border-slate-500 rounded-full h-2' >
                                <input
                                    type='range'
                                    min='0'
                                    max={duration ? duration.toFixed(2) : 0}
                                    step='.01'
                                    value={seek}
                                    onChange={handleSeekingChange}
                                    onMouseDown={handleMouseDownSeek}
                                    onMouseUp={handleMouseUpSeek}
                                    onTouchStart={handleMouseDownSeek}
                                    onTouchEnd={handleMouseUpSeek}
                                    className='w-full appearance-none rounded-full h-4 cursor-pointer opacity-0 absolute -top-1 left-0 z-20 shadow-inner [&::-webkit-slider-thumb]:opacity-0 [&::-moz-range-thumb]:opacity-0'
                                />
                                <div style={{ width: `${progressPercent}%` }} className={`h-1.5 z-10 bg-gradient-to-r to-teal-400 from-green-400  absolute top-0 rounded-full left-0`} ></div>
                                <span className='absolute left-0 top-3.5 text-xs text-slate-400' >{formatTime(Math.round(seek))}</span>
                                <span className='absolute right-0 top-3.5 text-xs text-slate-400' >{(duration) ? formatTime(Math.round(duration)) : 'NaN'}</span>
                                <div className=' w-full absolute -top-1 -translate-y-[110%] left-0 ' ><Lottie lottieRef={animateRef} animationData={barsAni} /></div>
                            </div>
                            <div className=' mt-8 w-full max-w-xl lg:max-w-full flex items-center justify-between ' >
                                <button type='button' className={'relative z-10 focus:outline-none' + (loop ? " text-green-400 " : " text-white ")} onClick={handleLoopToggle} ><RxLoop size={18} /></button>
                                <button type='button' className=' relative z-10 focus:outline-none text-green-400' onClick={handlePrev} ><MdSkipPrevious size={40} /></button>
                                <button type='button' disabled={playLoading} className=' disabled:opacity-50 relative z-10 focus:outline-none rounded-full p-2.5 bg-green-400' onClick={handleToggle} >{!playing ? <IoPlay className='pl-0.5' size={32} /> : <FaPause size={32} />}</button>
                                <button type='button' className=' relative z-10 focus:outline-none text-green-400' onClick={handleNext} > <MdSkipNext size={40} /> </button>
                                <button type='button' className={' relative z-10 focus:outline-none' + (mute ? " text-green-400 " : " text-white ")} onClick={handleMuteToggle} ><FaVolumeXmark size={18} /></button>
                            </div>
                        </div>}
                        {viewSong && showViewSong && <div className='flex items-center justify-center mt-4' >
                            <button type='button' disabled={playLoading} className=' disabled:opacity-50 relative z-10 focus:outline-none rounded-full p-2.5 bg-green-400' onClick={handleViewSongPlay} ><IoPlay className='pl-0.5' size={32} /></button>
                        </div>}
                    </div>
                    {(song?.lyrics?.length > 5 || viewSong?.lyrics?.length > 5) && <div className='w-full md:w-auto px-1' >
                        <Lyrics lyrics={showViewSong ? viewSong?.lyrics : song?.lyrics} />
                    </div>}
                </div>
            </div>
        </>
    );
};

export default MusicPlayer;