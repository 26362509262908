import { useSelector } from "react-redux"
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@/components/ui/hover-card"
import { GiMusicSpell } from "react-icons/gi";
import { Button } from "../ui/button";

export const SongCardSkeleton = () => {
    return (
        <div className=" animate-pulse" >
            <div className={"w-full overflow-hidden rounded-md aspect-square p-4 bg-slate-700 border-2 border-slate-600"} >
            </div>
            <p className="mt-3.5 h-4 rounded-full mb-1 w-full bg-slate-700" ></p>
            <div className=" flex gap-2 text-xs mt-3 font-semibold text-slate-400 font-comforta" >
                <span className="h-3 w-10 bg-slate-700 rounded-full" ></span>
                <span className="h-3 w-10 bg-slate-700 rounded-full" ></span>
                <span className="h-3 w-10 bg-slate-700 rounded-full" ></span>
                <span className="h-3 w-10 bg-slate-700 rounded-full" ></span>
            </div>
            <div className="flex mt-3 gap-3" >
                <div className=" h-3 w-8 bg-slate-700 rounded-full" ></div>
                <div className=" h-3 w-8 bg-slate-700 rounded-full"></div>
                <div className=" h-3 w-5 bg-slate-700 rounded-full"></div>
                <div className=" h-3 w-5 bg-slate-700 rounded-full"></div>
            </div>
        </div>
    )
}
export const SongCardSkeletonMobile = () => {
    return (
        <div className={"flex gap-2 h-12 w-full rounded animate-pulse"}>
            <div className={" flex-shrink-0 object-cover w-12 h-12 bg-slate-700 rounded "}></div>
            <div className="flex flex-col justify-between truncate w-full " >
                <p className="h-3 w-4/5 bg-slate-700 rounded-full mt-1" ></p>
                <div className="flex items-center gap-2.5 text-xs truncate text-slate-400" >
                    <p className="h-2.5 rounded-full bg-slate-700 w-1/5" ></p>
                    -
                    <div className="flex items-center gap-1.5 truncate" >
                        <span className="w-7 h-2 bg-slate-700 rounded-full" ></span>
                        <span className="w-12 h-2 bg-slate-700 rounded-full" ></span>
                        <span className="w-5 h-2 bg-slate-700 rounded-full" ></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const CreateSongIconLoading = () => {

    const { creatingSongs } = useSelector(state => state.create)

    return (
        <HoverCard>
            <HoverCardTrigger asChild>
                <Button className=" animate-border2 text-green-400 rounded-full p-2 border-2 w-10 " ><GiMusicSpell size={24} /> </Button>
            </HoverCardTrigger>
            <HoverCardContent className=" mr-4 w-auto bg-slate-900 border-slate-700 text-slate-10 p-2">
                <div className="flex flex-col gap-2">
                    {creatingSongs?.map((song) => (
                        <div key={song.creationId} className="flex items-center gap-2 bg-slate-950 p-2 rounded animate-pulse" >
                            <div className="w-8 rounded-full border aspect-square flex items-center justify-center" >
                                <img src={song?.ani} alt="animation" className="w-full p-1" />
                            </div>
                            <p className="text-sm" >{song.text}</p>
                        </div>
                    ))}
                </div>
            </HoverCardContent>
        </HoverCard>
    )
}