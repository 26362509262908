import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './index.css';
import { Provider } from 'react-redux';
import store from './redux/store.js';
import { Wallet } from './components/walletConnect/WalletConnect.jsx';
import ErrorBoundary from './components/errorBoundry/ErrorBoundry.jsx';

ReactDOM.createRoot(document.getElementById('root')).render(
  <ErrorBoundary>
    <Provider store={store}>
      <Wallet>
        <App />
      </Wallet>
    </Provider>
  </ErrorBoundary>
)
