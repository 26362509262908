import TopBar from '@/components/navbar/TopBar'
import BottomBar from '@/components/sidebar/BottomBar'
import axios from 'axios'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BsFillRocketFill, BsFillRocketTakeoffFill } from 'react-icons/bs'
import { FaCheck } from 'react-icons/fa6'
import { GiRocketThruster } from 'react-icons/gi'
import { useSelector } from 'react-redux'

const Subscription = () => {

    const [loading, setLoading] = useState(false)
    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const buyPremium = async (plan) => {

        if (!user) {
            return toast.error("Please login to continue", { id: "premium" })
        }

        const token = window.localStorage.getItem('token')
        try {
            setLoading(true)
            toast.loading("Opening Payment Page", { id: "premium" })
            const response = await axios.post("/checkout",
                { catagory: plan },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            console.log(response.data);
            window.location.href = response.data.url
            setLoading(false)
            // toast.success("Payment Successful", { id: "premium" })
        } catch (error) {
            toast.success("Payment Failed", { id: "premium" })
            setLoading(false)
            console.log(error);
        }
    }


    return (
        <div className='relative min-h-screen bg-slate-950 overflow-hidden flex flex-col flex-grow w-full' >
            <TopBar heading={"Buy Premium"} />
            <div className='pt-20 px-4 pb-32' >
                <h1 className='text-4xl font-bold text-slate-100 text-center' >Choose Your Plan</h1>
                <p className='text-center mt-2' >Select the perfect plan that fits your needs. Enjoy exclusive benefits and start your journey today!</p>
                <div className='grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 justify-items-center gap-8 2xl:gap-16 mt-8 sm:mt-16 w-full xl:max-w-screen-lg mx-auto' >
                    <div className='rounded-2xl flex flex-col max-w-xs w-full border border-green-600/75 hover:shadow-xl hover:shadow-green-700/50 p-4 bg-green-300/10' >
                        <h3 className='text-4xl font-bold uppercase text-green-400 flex items-center gap-2' >
                            <BsFillRocketFill size={24} className='mt-1' />
                            Premium
                        </h3>
                        <div className='flex items-center my-8' >
                            <p className='text-7xl font-bold text-green-400 ' >$14<span className='text-base font-medium text-slate-200' >/month</span> </p>
                        </div>
                        <div className=' flex-grow flex flex-col  gap-2 mb-8' >
                            <Benifit text={"5,000 Credits"} />
                            <Benifit text={"3 simultaneous song generations"} />
                        </div>
                        <button onClick={() => buyPremium(1)} disabled={loading || user?.plan >= 1} className='disabled:opacity-50 bg-green-400 hover:bg-green-500 w-full text-black h-10 rounded font-bold' >{user?.plan === 1 ? "Your current plan" : user?.plan === 2 ? "Subscribed to Gold " : user?.plan === 3 ? "Subscribed to Platinum" : "Subscribe"}</button>
                    </div>
                    <div className='rounded-2xl flex flex-col max-w-xs w-full border border-amber-600/75 hover:shadow-xl hover:shadow-amber-700/50 p-4 bg-amber-300/10' >
                        <h3 className='text-4xl font-bold uppercase text-amber-300 flex items-center gap-2' >
                            <BsFillRocketTakeoffFill size={24} className=' mt-1' />
                            Gold
                        </h3>
                        <div className='flex items-center my-8' >
                            <p className='text-7xl font-bold text-amber-300 ' >$19<span className='text-base font-medium text-slate-200' >/month</span> </p>
                        </div>
                        <div className=' flex-grow flex flex-col  gap-2 mb-8' >
                            <Benifit text={"10,000 Credits"} />
                            <Benifit text={"5 simultaneous song generations"} />
                        </div>
                        <button onClick={() => buyPremium(2)} disabled={loading || user?.plan >= 2} className='disabled:opacity-50 bg-amber-300 hover:bg-amber-400 w-full text-black h-10 rounded font-bold' >{user?.plan === 2 ? "Your curent plan " : user?.plan === 3 ? "Subscribed to Platinum" : "Subscribe"}</button>
                    </div>
                    <div className='rounded-2xl max-w-xs w-full border border-slate-100/75 hover:shadow-xl hover:shadow-slate-700 p-4 bg-slate-100/10' >
                        <h3 className='text-4xl font-bold uppercase text-slate-100 flex items-center gap-2' >
                            <GiRocketThruster size={24} className='mt-1' />
                            Platinum
                        </h3>
                        <div className='flex items-center my-8' >
                            <p className='text-7xl font-bold text-slate-100 ' >$45<span className='text-base font-medium text-slate-200' >/month</span> </p>
                        </div>
                        <div className=' flex-grow flex flex-col  gap-2 mb-8' >
                            <Benifit text={"50,000 Credits"} />
                            <Benifit text={"10 simultaneous song generations"} />
                        </div>
                        <button onClick={() => buyPremium(3)} disabled={loading || user?.plan === 3} className='disabled:opacity-50 bg-slate-100 hover:bg-slate-200 w-full text-black h-10 rounded font-bold' >{user?.plan === 3 ? "Your current plan" : "Subscribe"}</button>
                    </div>
                </div>
            </div>
            <BottomBar />
        </div>
    )
}

export default Subscription


function Benifit({ text }) {
    return (
        <div className='flex items-center gap-2' >
            <FaCheck size={24} />
            <p className='font-medium text-sm' >{text}</p>
        </div>
    )
}