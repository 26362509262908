import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    term: "",
    searchSongs: [],
}

const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        SET_SEARCHSONGS(state, action) {
            state.searchSongs = action.payload
        },
        SET_TERM(state, action) {
            state.term = action.payload
        }
    }
});

export const {
    SET_SEARCHSONGS,
    SET_TERM
} = searchSlice.actions

export default searchSlice.reducer