import { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import { RiMenuFill } from "react-icons/ri";
import footerFrog from "../../assets/footer-frog.png";
import { Link } from "react-router-dom";

const Navbar = ({ children }) => {
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [shadow, setShadow] = useState(false);
  const [navMenu, setNavMenu] = useState(false);

  const handleNavMenu = () => {
    setNavMenu(!navMenu);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 64) {
        if (window.scrollY < lastScrollY) {
          setShow(true);
          setShadow(true);
        } else {
          setShow(false);
        }
      } else {
        setShow(true);
        setShadow(false);
      }
      setLastScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      <div className={`fixed top-0 left-0 w-full z-[1500] md:py-2 transition-transform duration-300 overflow-x-hidden bg-[#8FC9F7]  ${show ? 'translate-y-0' : '-translate-y-32'} ${shadow ? 'shadow-md' : ''}`}>
        <div className="max-w-screen-xl w-full mx-auto px-4 md:px-10 h-16 flex justify-between items-center">
          <div className=" flex items-center space-x-4">
            <img className="w-12" src={footerFrog} alt="logo" />
            <a href="/" className="text-2xl font-potta text-white hover:text-[#3a963d] hover:text-shadow-md">$SOME</a>
          </div>
          <div className="flex items-center justify-end gap-4 font-inter flex-grow">
            <div className="hidden lg:flex gap-4 items-center">{children}</div>
            <div className="flex gap-5 items-center">
              {children && children?.length !== 0 && (
                <RiMenuFill
                  size={28}
                  onClick={handleNavMenu}
                  className="lg:hidden text-[#3a963d]  hover:text-white block"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`fixed top-0 left-0 z-[1500] w-full h-full flex flex-col shadow-2xl lg:hidden bg-[#8FC9F7] backdrop-blur-sm transition-transform duration-500 ${navMenu ? '' : 'translate-x-[120%]'}`}      >
        <div className="h-16 w-full flex justify-end items-center px-5 text-[#3a963d]  hover:text-white ">
          <FaArrowRight size={32} onClick={handleNavMenu} />
        </div>
        <div className="flex-grow flex flex-col justify-center items-center gap-10 text-center text-3xl font-extrabold font-inter px-5" onClick={handleNavMenu}        >
          {children}
          <Link to="/privacy-policy" className="text-white truncate hover:text-[#3a963d] hover:text-shadow-md font-potta ">Privacy Policy</Link>
        </div>
      </div>
    </>
  );
};

export default Navbar;
