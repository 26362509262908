import { useNavigate } from "react-router-dom";
import { Button } from "../ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader } from "../ui/card";
import { TabsContent } from "../ui/tabs";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { v4 as uuidv4 } from 'uuid';
import { APPEND_SONGS } from "@/redux/features/music/musicSlice";
import axios from "axios";
import ani1 from "../../assets/createani1.gif"
import ani2 from "../../assets/createani2.gif"
import ani3 from "../../assets/createani3.gif"
import { SET_CREDITS } from "@/redux/features/auth/authSlice";
import { ADD_CREATING_SONG, REMOVE_CREATING_SONG, SET_CREATEDESC, UPDATE_CREATING_SONG } from "@/redux/features/music/createSlice";

const CreateSongComp = () => {

    const genreTags = [
        'Pop', 'Rock', 'Jazz', 'Blues', 'Classical', 'Country', 'Electronic', 'Dance',
        'Hip Hop', 'Rap', 'R&B', 'Soul', 'Reggae', 'Folk', 'Metal', 'Punk', 'Funk',
        'Disco', 'House', 'Techno', 'Trance', 'Dubstep', 'Drum and Bass', 'Ambient',
        'Indie', 'Gospel', 'Opera', 'Ska', 'Grunge', 'Swing', 'Bluegrass', 'Latin',
        'Flamenco', 'Celtic', 'World', 'New Age', 'EDM', 'Lo-Fi', 'Alternative',
        'K-Pop', 'J-Pop', 'C-Pop', 'Afrobeat', 'Bollywood', 'Heavy Metal', 'Thrash Metal',
        'Black Metal', 'Glitch Hop', 'Trap', 'Grime'
    ];

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [selectedTags, setSelectedTags] = useState([]);
    // creating ref to use in change status function
    const creatinRef = useRef({})
    const { user } = useSelector(state => state.auth)
    const { createDesc } = useSelector(state => state.create)
    const [toPublish, setToPublish] = useState(true)
    const pollIntervalsRef = useRef({});
    const [isLoading, setIsLoading] = useState(false)

    const updateSelectedTagsFromDescription = useCallback((description) => {
        const tags = description.split(',').map(tag => tag.trim());
        const validTags = tags.filter(tag => genreTags.includes(tag));
        setSelectedTags(validTags);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Update selectedTags whenever createDesc changes
        updateSelectedTagsFromDescription(createDesc);
    }, [createDesc, updateSelectedTagsFromDescription]);

    // handle toggle tags
    const handleToggleTag = (tag) => {
        let newDesc;
        const parts = createDesc.split(',').map(part => part.trim()).filter(part => part !== '');

        if (selectedTags.includes(tag)) {
            // Remove tag
            newDesc = parts.filter(part => part !== tag).join(', ');
        } else {
            // Add tag
            if (selectedTags.length >= 5) {
                return toast.error("Only 5 tags allowed", { id: "warningTag" });
            }
            if (parts.includes(tag)) {
                return; // Tag already exists, do nothing
            }
            newDesc = [...parts, tag].join(', ');
        }

        // if (newDesc.length > 500) {
        //     return toast.error("Max 500 characters allowed", { id: "chars" });
        // }

        dispatch(SET_CREATEDESC(newDesc));
        // selectedTags will be updated by the useEffect
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        dispatch(SET_CREATEDESC(newValue));
        // selectedTags will be updated by the useEffect
    };

    // Create request
    const handleCreate = async () => {
        // validation
        if (!createDesc || createDesc.length === 0 || createDesc === ",") {
            return toast.error("Please enter song description", { id: "create" })
        }
        const creationId = uuidv4();
        try {
            setIsLoading(true)
            toast.loading("Initializing", { id: "credits" })
            const token = localStorage.getItem('token')
            const { data } = await axios.get("/balance", { headers: { Authorization: `Bearer ${token}` } })
            dispatch(SET_CREDITS(data?.amount))
            if (data?.amount < 100) {
                return toast.error("Not enough credits", { id: "credits" })
            } else {
                toast.dismiss("credits")
            }
            toast.loading("Sending Request", { id: creationId })
            const response = await axios.post("https://engine.soundofmeme.com/create",
                { prompt: createDesc, publish: toPublish },
                { headers: { Authorization: `Bearer ${token}` } })

            const songIds = response.data.songs
            const songIdsArr = songIds?.split(',')
            const songsArray = songIdsArr.map(id => ({ song_id: parseInt(id) }))
            creatinRef.current[creationId] = true
            dispatch(ADD_CREATING_SONG({ creationId: creationId, feat: "create", songs: songsArray }))
            changeStatus(creationId)
            startPollingForSongCompletion(songIds, creationId)
            setIsLoading(false)
            dispatch(SET_CREATEDESC(''))
            navigate("/creations")
        } catch (error) {
            setIsLoading(false)
            handleError(error, creationId)
        }
    }

    // Polling method for song completion
    const startPollingForSongCompletion = (songIds, creationId) => {
        const interval = setInterval(async () => {
            try {
                const token = localStorage.getItem('token');
                const { data } = await axios.get(`/songs/?song_ids=${songIds}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                if (data === null) {
                    toast.error("Unexpected Error, Received null response")
                    clearInterval(interval)
                    delete pollIntervalsRef.current[songIds]
                    dispatch(REMOVE_CREATING_SONG({ creationId }));
                    delete creatinRef.current[creationId];
                } else if (data.status === 'completed') {
                    clearInterval(interval);
                    delete pollIntervalsRef.current[songIds];
                    dispatch(REMOVE_CREATING_SONG({ creationId }));
                    dispatch(APPEND_SONGS(data.songs));
                    toast.success(`All done! Your song is ready to play.`, { id: creationId });
                    delete creatinRef.current[creationId];
                } else {
                    dispatch(UPDATE_CREATING_SONG({ creationId: creationId, songs: data.songs }))
                }
            } catch (error) {
                clearInterval(interval)
                delete pollIntervalsRef.current[songIds];
                handleError(error, creationId);
            }
        }, 10000);
        // Store interval ID for cleanup
        pollIntervalsRef.current[songIds] = interval;
    };

    // Handle errors
    const handleError = (error, creationId) => {
        console.error(error);
        dispatch(REMOVE_CREATING_SONG({ creationId }));
        delete creatinRef.current[creationId];
        toast.error(error?.response?.data?.detail || error.message || "Oops! Something went wrong. Please try again.", { id: creationId });
    };

    // Change status function
    const changeStatus = useCallback((creationId) => {
        toast("Crafting your tune, hang tight!", { id: creationId })
        dispatch(UPDATE_CREATING_SONG({ creationId: creationId, ani: ani1, text: "Analyzing your description..." }))
        setTimeout(() => {
            if (!creatinRef.current[creationId]) return toast.dismiss(creationId);
            dispatch(UPDATE_CREATING_SONG({ creationId: creationId, ani: ani2, text: "Adding the rhythms..." }))
        }, 15000);
        setTimeout(() => {
            if (!creatinRef.current[creationId]) return toast.dismiss(creationId);
            dispatch(UPDATE_CREATING_SONG({ creationId: creationId, ani: ani3, text: "Composing your melody..." }))
        }, 30000);
    }, [dispatch])

    return (
        <TabsContent value="create" >
            <Card className=" bg-transparent border-0" >
                <div className="flex justify-between items-center text-slate-400 mb-1 text-sm" >
                    <p className="font-semibold" >Credits: <span>{user?.credits}</span></p>
                    <p className="font-semibold" >Cost: <span>100</span></p>
                </div>
                <CardHeader className="p-0 my-2" >
                    <CardDescription className="text-slate-400 text-sm font-medium" >
                        Describe the mood, theme, and story for your personalized track.
                    </CardDescription>
                </CardHeader>
                <CardContent className=" flex flex-col gap-4 text-slate-200 p-0 mt-4">
                    <div className="relative ">
                        <div className="flex items-center gap-4 mb-2 justify-between text-xs font-medium text-slate-300" >
                            <p>Description and Genre</p>
                            {/* <p>{createDesc?.length}/500</p> */}
                        </div>
                        <textarea
                            id="description"
                            type="text"
                            value={createDesc}
                            onChange={handleChange}
                            // maxLength={500}
                            className="w-full bg-slate-900 rounded-md min-h-40 p-2 placeholder:text-sm placeholder:text-slate-500 border border-slate-700/75 focus:outline-none focus:ring-1 ring-green-400 "
                        // placeholder="Genre and description"
                        ></textarea>
                    </div>
                    <div className="flex items-center  gap-2 overflow-x-auto whitespace-nowrap w-full" >
                        {genreTags.map(tag => (
                            <button className={"text-xs py-0.5 px-1 rounded-md mb-2 w-full " + (selectedTags.includes(tag) ? " bg-green-400 text-black " : " bg-slate-700 ")} key={tag} onClick={() => handleToggleTag(tag)}>
                                {tag}
                            </button>
                        ))}
                    </div>
                    <label className="switch flex items-center justify-between border-b  pb-1 border-slate-700 cursor-pointer ">
                        <p className="font-semibold text-sm" >Publish</p>
                        <input checked={toPublish} onChange={(e) => setToPublish(e.target.checked)} type="checkbox" />
                        <span className="slider"></span>
                    </label>
                </CardContent>
                <CardFooter className="p-0 mt-6">
                    <Button disabled={isLoading} onClick={handleCreate} className=' disabled:opacity-50 w-full bg-green-400 text-black hover:bg-green-300 font-semibold' >Create Song</Button>
                </CardFooter>
            </Card>
        </TabsContent>
    )
}

export default CreateSongComp