import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    btnTxt: "Buy Presale",
}

const preslaeSlice = createSlice({
    name: "presale",
    initialState,
    reducers: {
        SET_BTNTEXT(state, action) {
            state.btnTxt = action.payload
        }
    }
});

export const { SET_BTNTEXT } = preslaeSlice.actions

export default preslaeSlice.reducer