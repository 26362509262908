import axios from "axios"

// login user
const loginUser = async (formData) => {
    const response = await axios.post("/login", formData)
    return response.data
}
// signup user
const signUpUser = async (formData) => {
    const response = await axios.post("/signup2", formData)
    return response.data
}

// get user details
const getUserDetails = async () => {
    const token = localStorage.getItem('token')
    const response = await axios.get("/user", { headers: { Authorization: `Bearer ${token}` } })
    return response.data
}

const verifyOtp = async (formData) => {
    const otpToken = sessionStorage.getItem('otpToken')
    const response = await axios.post("/verify", formData, { headers: { Authorization: `Bearer ${otpToken}` } })
    return response.data

}

const authService = {
    loginUser,
    signUpUser,
    getUserDetails,
    verifyOtp
}

export default authService