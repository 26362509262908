import Navbar from "../../components/navbar/Navbar";
import image from '../../assets/white-paper.png';
import { Link } from "react-router-dom";
// import PlayButton from "../../components/player/PlayButton";

const WenWhite = () => {
    return (
        <>
            <Navbar>
                <Link to="/" className="text-white hover:text-[#3a963d] hover:text-shadow-md font-potta" >Home</Link>
            </Navbar>
            <div className="w-full min-h-screen z-10 backdrop-blur-sm font-comforta bg-gradient-to-b from-[#8FC9F7] to-[#267FC6] flex flex-col justify-center items-center" >
                <div className="max-w-screen-lg mx-auto px-4 md:px-10 pt-24 flex flex-col  items-center justify-center" >
                    <h1 className="text-3xl md:text-4xl lg:text-5xl text-white font-potta text-center" >Venn White Paper ✅</h1>
                    <div className="flex items-center justify-center" >
                        <img className="max-w-2xl w-[70%] mt-8" src={image} alt="image" />
                    </div>
                    {/* <div className="flex items-center justify-center py-6" ><PlayButton /></div> */}
                </div>
            </div>
        </>
    )
}

export default WenWhite