import { useSelector } from "react-redux";
import { Button } from "../ui/button";
import { BiLogInCircle } from "react-icons/bi";
import { ProfileIcon } from "../profileIcon/ProfileIcon";
import { CreateSongIconLoading } from "../createSong/CreateSongLoading";
import { PiMusicNotesPlusFill } from "react-icons/pi";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { FaTelegramPlane } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const TopBar = ({ heading }) => {

    const { user } = useSelector(state => state.auth)
    const { creatingSongs } = useSelector(state => state.create)
    const navigate = useNavigate()
    const location = useLocation()

    const handleClick = () => {
        if (user) {
            navigate("/create")
        } else {
            toast.error("Login to create a song", { id: 'login' })
            navigate("/login")
        }
    }

    return (
        <div className=' h-16 fixed top-0 left-0 right-0 z-[100] bg-slate-900 md:ml-52 lg:ml-64 flex justify-between items-center gap-4  p-4 borde border-slate-800' >
            <h3 className='font-potta text-xl xs:text-2xl text-white' >{heading}</h3>
            <div className="flex items-center gap-4 md:gap-4" >
                <a href="https://t.me/SoundOfMeme" target="_blank" className=" hidden md:block p-2 w-10 hover:text-green-400" ><FaTelegramPlane size={24} /> </a>
                <a href="https://twitter.com/SoundOfMemeSol" target="_blank" className=" hidden md:block p-2 w-10 hover:text-green-400" ><FaXTwitter size={24} /> </a>
                {creatingSongs.length > 0 && <CreateSongIconLoading />}
                {/* <CreateSongDialog /> */}
                <Button onClick={handleClick} className='items-center justify-center gap-1 rounded bg-green-400 text-black hover:bg-green-300 font-semibold px-4 py-2 hidden md:flex' ><PiMusicNotesPlusFill className="text-black mt-1" size={20} /> Create</Button>
                {user ? <ProfileIcon /> :
                    location.pathname !== "/login" ?
                        <Button className="bg-green-400 text-black hover:bg-green-300 font-semibold px-4 py-2  flex items-center gap-2" onClick={() => navigate("/login")} >Login<BiLogInCircle className="mt-1" size={24} /></Button>
                        : null}
            </div>
        </div>
    )
}

export default TopBar