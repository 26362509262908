import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"
import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList,
} from "@/components/ui/command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"

const frameworks = [
    {
        value: "mostrecent",
        label: "Most Recent",
    },
    {
        value: "mostliked",
        label: "Most Liked",
    },
    {
        value: "mostviewed",
        label: "Most Played",
    }
]

const SortFilter = ({ setSort, sortValue }) => {
    const [open, setOpen] = React.useState(false)

    const handleSelect = (currentValue) => {
        if (currentValue === sortValue) {
            setOpen(false)
            return
        }
        setSort(currentValue, 1)
        setOpen(false)
    }

    return (
        <Popover open={open} onOpenChange={setOpen} >
            <PopoverTrigger asChild>
                <Button
                    variant="outline"
                    role="combobox"
                    aria-expanded={open}
                    className="w-[130px] lg:w-[200px] justify-between bg-slate-800 hover:bg-slate-700 hover:text-white border-0"
                >
                    {sortValue
                        ? frameworks.find((framework) => framework.value === sortValue)?.label
                        : "Sort.."}
                    <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-[130px] lg:w-[200px] p-0 border-0 ">
                <Command className="bg-slate-800 " >
                    <CommandList >
                        <CommandGroup className="bg-slate-800 text-slate-300">
                            {frameworks.map((framework) => (
                                <CommandItem
                                    key={framework.value}
                                    value={framework.value}
                                    onSelect={handleSelect}
                                    className={`hover:cursor-pointer data-[selected='true']:bg-slate-700 data-[selected='true']:text-white ${framework.value === sortValue && " text-green-400  data-[selected='true']:text-green-400 "} `}
                                >
                                    <Check
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            sortValue === framework.value ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                    {framework.label}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}

export default SortFilter