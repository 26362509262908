import CreateSong from '@/components/createSong/CreateSong'
import TopBar from '@/components/navbar/TopBar'
import BottomBar from '@/components/sidebar/BottomBar'
import useAuthCheck from '@/customHook/useAuthCheck'
import Lottie from 'lottie-react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import ani from "../../assets/createani3.json"
import { Link } from 'react-router-dom'

const Create = () => {

    useAuthCheck("/login")
    const { song } = useSelector(state => state.music)
    const { creatingSongs } = useSelector(state => state.create)
    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className='relative min-h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 overflow-hidden flex flex-col flex-grow w-full' >
            <TopBar heading={"Create Song"} />
            <div className={'md:pt-20 pt-20 flex-grow flex sm:items-center justify-center ' + (song ? " md:pb-32 pb-36 " : " pb-20 ")} >
                {(user?.plan === 0 && creatingSongs.length >= 1
                    || user?.plan === 1 && creatingSongs.length >= 3
                    || user?.plan === 2 && creatingSongs.length >= 5
                    || user?.plan === 3 && creatingSongs.length >= 10)
                    ?
                    <div className=' flex-grow flex-col flex items-center justify-center pb-32 px-4' >
                        <div className='max-w-xs w-full' >
                            <Lottie animationData={ani} loop />
                        </div>
                        <h3 className='text-3xl font-semibold text-green-400 text-center ' >
                            {user?.plan === 0 && "More Songs Await!"}
                            {user?.plan === 1 || user?.plan === 2 && "Need More Bandwidth?"}
                            {user?.plan === 3 && "Creating Your Songs"}
                        </h3>
                        {user?.plan === 0 ?
                            <p className='text-center mt-2' >You can only create 1 song at a time. Unlock more creations by upgrading your plan!</p>
                            :
                            user?.plan <= 2 ?
                                <p className='text-center mt-2' >You can currently create up to
                                    {user?.plan === 1 ? " 2 " : user?.plan === 2 ? " 3 " : " "}
                                    songs simultaneously. Unlock more creations by upgrading your plan!
                                </p>
                                :
                                user?.plan === 3 ?
                                    <p className='text-center mt-2' >Maximum 5 simultaneously song generations are allowed .</p>
                                    : null
                        }
                        {user?.plan < 3 && <Link to="/premium" className='mt-2 text-green-400 font-semibold text-lg' >Upgrade</Link>}

                    </div>
                    :
                    <CreateSong />
                }
            </div>
            <BottomBar />
        </div>
    )
}

export default Create