import { SongCardSkeleton, SongCardSkeletonMobile } from "@/components/createSong/CreateSongLoading"
import TopBar from "@/components/navbar/TopBar"
import BottomBar from "@/components/sidebar/BottomBar"
import SongCard from "@/components/songCard/SongCard"
import SongCardSearch from "@/components/songCard/SongCardSearch"
import { SET_SEARCHSONGS, SET_TERM } from "@/redux/features/music/searchSlice"
import axios from "axios"
import { debounce } from "lodash"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const Search = () => {

    const dispatch = useDispatch()
    const { term, searchSongs } = useSelector(state => state.search)
    const { song } = useSelector(state => state.music)
    const [noResultMsg, setNoResultMsg] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    // Define the debounced function directly inside useEffect
    useEffect(() => {
        const debouncedSearch = debounce(async () => {
            if (!term) return;
            try {
                setIsLoading(true)
                const response = await axios.get(`/search?item=${term}`);
                dispatch(SET_SEARCHSONGS(response.data.songs))
                setIsLoading(false)
                if (response?.data?.songs?.length === 0) {
                    setNoResultMsg(true)
                } else {
                    setNoResultMsg(false)
                }
            } catch (error) {
                setIsLoading(false)
                console.error('Search failed:', error);
            }
        }, 300);
        debouncedSearch();
        return () => {
            debouncedSearch.cancel();
        };
    }, [term, dispatch]);

    const handleChange = async (e) => {
        dispatch(SET_TERM(e.target.value))
        // setIsLoading(true)
    }

    useEffect(() => {
        if (term === "") {
            setNoResultMsg(false)
            dispatch(SET_SEARCHSONGS([]))
            setIsLoading(false)
        }
    }, [dispatch, term])

    return (
        <div className='relative min-h-screen bg-slate-950 overflow-hidden flex flex-col flex-grow w-full' >
            <TopBar heading={"Search"} />
            <div className="pt-16" >
                <div className='mt-8 pb-2 flex justify-between gap-4 px-4' >
                    <input type='text' placeholder='Search all songs'
                        onChange={handleChange}
                        value={term}
                        className='flex-grow bg-transparent border border-slate-700 p-2 rounded w-full focus:outline-green-700'
                    />
                </div>
                {searchSongs.length > 0 && !noResultMsg && !isLoading &&
                    <>
                        <div className={' hidden  flex-grow p-4 w-full sm:grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-8 ' + (song ? " pb-36 md:pb-28 " : " pb-24 ")} >
                            {searchSongs && searchSongs.map((song) => (
                                <SongCard key={song?.song_id} song={song} playingFrom={"search"} />
                            ))}
                        </div>
                        <div className={"sm:hidden grid gap-2.5 p-4 " + (song ? " pb-36 md:pb-28 " : " pb-24 ")} >
                            {searchSongs && searchSongs.map((song) => (
                                <SongCardSearch key={song?.song_id} song={song} playingFrom={"search"} />
                            ))}
                        </div>
                    </>
                }
                {!isLoading && !noResultMsg && searchSongs.length === 0 && <p className="px-4 mt-4">Ready to find your next favorite song? Start searching now!</p>}
                {isLoading && !noResultMsg &&
                    <>
                        <div className={' hidden flex-grow p-4 w-full sm:grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-4 gap-y-8 ' + (song ? " pb-36 md:pb-28 " : " pb-24 ")} >
                            <Loader />
                        </div>
                        <div className={"sm:hidden grid gap-2.5 p-4 " + (song ? " pb-36 md:pb-28 " : " pb-24 ")} >
                            <LoaderMobile />
                        </div>
                    </>
                }
                {noResultMsg && !isLoading && <p className="px-4 mt-4">No results found. Try different search terms.</p>}
            </div>
            <BottomBar />
        </div>
    )
}

export default Search


function Loader() {
    return (
        <>
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
            <SongCardSkeleton />
        </>
    )
}
function LoaderMobile() {
    return (
        <>
            <SongCardSkeletonMobile />
            <SongCardSkeletonMobile />
            <SongCardSkeletonMobile />
            <SongCardSkeletonMobile />
            <SongCardSkeletonMobile />
            <SongCardSkeletonMobile />
            <SongCardSkeletonMobile />
            <SongCardSkeletonMobile />
            <SongCardSkeletonMobile />
        </>
    )
}