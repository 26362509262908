import { useState, useEffect } from 'react';
import Navbar from "../../components/navbar/Navbar";
// import CountdownTimer from '../../components/Timer/timer';
import { HashLink as Link } from "react-router-hash-link";
//import { Link } from "react-router-dom";
//import toast from "react-hot-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { faCircleMinus } from '@fortawesome/free-solid-svg-icons';
import { faTelegram } from '@fortawesome/free-brands-svg-icons';
import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
// import { faInstagram } from '@fortawesome/free-brands-svg-icons';
//import { useSelector } from "react-redux";
import heroHeadImg from "../../assets/hero-head-img.png";
import heroImg from "../../assets/hero-img.png";
import hero from "../../assets/hero.gif";
import homeTopRight from "../../assets/hometopright.png";
import homeTopLeft from "../../assets/hometopleft.png";
import blueBird from "../../assets/blue bird.png";
import greenBird from "../../assets/greenbird.png";
import homebotRight from "../../assets/homebot-right.png";
import homebotleft from "../../assets/homebot-left.png";
import homeAboutDevide from "../../assets/homeabout divide.png";
import aboutHero from "../../assets/about-hero.png";
import airplaneflag from "../../assets/airplane.png";
import purpuleBird from "../../assets/purple-bird.png";
import exAboutHero from "../../assets/ex-about-hero.png";
import blueFrog from "../../assets/bluefrog.png";
import blueFrogBot from "../../assets/bluefrog-bottom.png";
import smallbluecloud from "../../assets/about-blue-cloud.png";
import greenFrog from "../../assets/green-frog.png";
import greenFrogBot from "../../assets/green-bot.png";
import supplySome from "../../assets/supply-some.png";
import tokenomics from "../../assets/Tokenomics.png";
import tokenomicsGraph from "../../assets/tokenomics-graph.png";
// import cardImg from "../../assets/card-img.png";
//import centerMeme from "../../assets/center-meme.png";
import footerImg from "../../assets/footer-img.png";
import footercloud from "../../assets/footer-cloud.png";
import footerfrog from "../../assets/footer-frog.png";
import memeOne from "../../assets/meme-1.png"
import memeTwo from "../../assets/meme-2.png"
import memeThr from "../../assets/meme-3.png"
import memeFou from "../../assets/meme-4.png"
import memeFiv from "../../assets/meme-5.png"
import memeSix from "../../assets/meme-6.png"
import memeSev from "../../assets/meme-7.png"
import memeEig from "../../assets/meme-9.png"
import memeElv from "../../assets/meme-11.png"
// import InvestSolDialog from '@/components/investSol/InvestSolDialog';
// import { MdOutlineContentCopy } from "react-icons/md";
// import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

// const { homeMusic } = useSelector((state) => state.music);
function Home() {

  const navigate = useNavigate()
  const [zoom, setZoom] = useState(false);

  const handleNavigation = (event, anchorId) => {
    event.preventDefault(); // Prevent the default anchor link behavior
    const anchorSection = document.getElementById(anchorId);
    if (anchorSection) {
      anchorSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // Initialize parallax effect
  useEffect(() => {
    const parallax = (e) => {
      document.querySelectorAll(".object").forEach(function (move) {
        const moving_value = parseFloat(move.getAttribute("data-value"));
        const x = (e.clientX * moving_value) / 350;
        const y = (e.clientY * moving_value) / 280;
        move.style.transform = `translateX(${x}px) translateY(${y}px)`;
      });
    };

    document.addEventListener("mouseover", parallax);

    return () => {
      document.removeEventListener("mouseover", parallax);
    };
  }, []);




  // Accordion state and handlers
  const [accordionState, setAccordionState] = useState({});

  const toggleAccordion = (index) => {
    setAccordionState((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const faqData = [
    { question: 'What chain is $SOME on?', answer: '$SOME runs on SOLANA ($SOL)' },
    // { question: 'When is the Presale?', answer: '23.06.2024 at 12AM UTC' },
    { question: 'Are there rewards for Presale Investors?', answer: 'Yes! Its all about how fast you invest once the Pre-Sale starts. We divide rewards in three segments: Everyone who sends funds in the first $1 Million collected (In Solana) receives 15% extra tokens. ﻿Contributors who send funds after the $2M Solana have been collected, gets 10% extra tokens. ﻿Contributors who invest after 3M have been collected gets 5% extra tokens.' },
    { question: 'When is the Token Listing', answer: 'The $SOME token will be listed & tradeable shortly after the Pre-Sale ends & all tokens have been distributed to Pre-Sale Buyers' },
  ];


  // function copyToClipboard(text) {
  //   navigator.clipboard.writeText(text).then(() => {
  //     toast.success("Address Copied")
  //   }).catch(err => {
  //     console.error('Failed to copy text to clipboard', err);
  //     toast.error("Failed to copy address")
  //   });
  // }


  function navigateDiscover() {
    window.scrollTo(0, 0)
    setZoom(true);
    setTimeout(() => {
      navigate("/discover");
    }, 500);
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className='w-full overflow-hidden' >
      <div className={`${zoom ? 'hidden' : ''}`} >
        <Navbar onNavigate={handleNavigation} >
          <Link to="/#home" className="text-white truncate hover:text-[#3a963d] hover:text-shadow-md font-potta ">Home</Link>
          <Link to="/#about" className="text-white truncate hover:text-[#3a963d] hover:text-shadow-md font-potta ">About</Link>
          <Link to="/#tokenomics" className="text-white truncate hover:text-[#3a963d] hover:text-shadow-md font-potta ">Tokenomics</Link>
          <Link to="/#meme" className="text-white truncate hover:text-[#3a963d] hover:text-shadow-md font-potta ">Meme</Link>
          <Link to="/#FandQ" className="text-white truncate hover:text-[#3a963d] hover:text-shadow-md font-potta ">FAQ</Link>
          <Link to="/paper" className="text-white truncate hover:text-[#3a963d] hover:text-shadow-md font-potta">Wen White paper?</Link>
          <Link to="/#presale" className="text-white truncate hover:text-[#3a963d] hover:text-shadow-md font-potta ">Presale Details</Link>
          {/* <Link to="/#timer" className="text-white truncate hover:text-[#3a963d] hover:text-shadow-md font-potta bg-[#3a963d] hover:bg-white py-2 px-4 rounded-3xl">Presale</Link> */}
        </Navbar>
      </div>
      <div id="hero" className={`relative min-h-screen mx-auto px-4 md:px-10 sm:px-4  w-full flex flex-col justify-center items-center transition-all duration-1000 bg-gradient-to-b from-[#8FC9F7] to-[#267FC6] ${zoom ? 'zoom' : ''} `}>
        <img src={blueBird} alt="blue bird aasembling frog " className="absolute w-16 md:w-24 3xl:w-40 top-3/4 z-[500] left-[2%]" data-value="12" data-aos="fade-right" />
        <img src={homeTopRight} alt="cloud" className="absolute w-24 md:w-36 3xl:w-56 object-cover right-[0%] top-[13%]  " data-value="0.5" data-aos="fade-up-left" />
        <img src={homeTopLeft} alt="cloud" className="absolute w-24 md:w-36 3xl:w-56 object-cover  left-[0%] top-[18%] " data-value="-8" data-aos="fade-up-right" />
        <img src={airplaneflag} alt="a flag with slogan 'where meme meet the beat' connect to aircraft " className="absolute left-[5%] top-[10%] xs:left-[5%] xs:top-[15%] w-[250px] lg:w-[320px]" data-value="7" data-aos="fade-left" />
        <img src={greenBird} alt="green bird aasembling frog" className="absolute top-[20%] right-[10%] sm:right-[20%] w-16 md:w-24 3xl:w-40 z-[500] " data-value="-7" data-aos="fade-down" />
        <img src={homebotRight} alt="cloud" className="absolute w-24 md:w-36 3xl:w-56 object-cover right-[0%] bottom-[10%] " data-value="0.5" data-aos="fade-up-left" />
        <img src={purpuleBird} alt="purple bird aasembling frog" className="absolute top-3/4 md:top-1/2 right-2 w-16 md:w-24 3xl:w-40 object-cover z-[200]" data-value="-8" data-aos="fade-left" />
        <img src={homebotleft} alt="cloud" className="absolute w-24 md:w-36 3xl:w-56 object-cover left-[0%] bottom-[23%]" data-value="-5" data-aos="fade-up-right" />
        <div data-aos="fade-up" data-aos-offset="0" className='absolute top-1/2 xl:top-2/3 2xl:top-auto 2xl:-bottom-24 3xl:-bottom-40 z-[100] w-full' >
          <div className='flex gap-4 absolute w-full justify-center top-1/2 left-0 z-[500]' >
            {/* <InvestSolDialog /> */}
            <button onClick={navigateDiscover} className=' flex items-center gap-2 justify-center rounded-md bg-[#34AC38] py-2 px-4 text-sm sm:text-base md:text-xl lg:text-2xl w-80 md:w-96 font-bold text-white focus:outline-none data-[hover]:bg-[#41c946] data-[focus]:outline-1 data-[focus]:outline-white animate-glow' >
              <img className='mt-[1px] w-9 p-1 bg-white rounded-full' src={footerfrog} alt='pepe' />LAUNCH APP</button>
          </div>
          <div className="absolute bottom-[180%] xs:bottom-[160%] sm:bottom-[140%] md:bottom-[130%] 2xl:bottom-[110%] 3xl:bottom-[100%] flex flex-col justify-center items-center  w-full">
            <div className=" relative w-full max-w-xs xs:max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl 2xl:max-w-[700px] 3xl:max-w-[900px] ">
              <img src={heroHeadImg} alt="image of $SOME" className="w-full" />
              <img src={hero} alt={heroImg} className="absolute  w-[180px] xs:w-[200px] sm:w-[270px] md:w-[300px] lg:w-[330px] xl:w-[380px] 2xl:w-[400px] 3xl:w-[550px] left-[35%] -bottom-[170%] z-10 justify-self-center self-center  " />
            </div>
          </div>
          <img src={homeAboutDevide} alt="big cloude" className=" relative z-[100] w-full scale-[200%] xs:scale-150 sm:scale-100 " />
        </div>
      </div>
      {/* <div id='timer' className="divider z-[100] relative w-full">
        <div className="absolute top-16 sm:top-1/3 md:1/2 lg:top-1/3 xl:top-1/2 flex flex-col  justify-center items-center w-full " >
          <h1 className=' justify-self-center self-center  text-[#34AC38] hover:text-[#f9ed0a] font-potta  text-2xl xs:text-3xl sm:text-4xl lg:text-6xl xs:mt-6 sm:mt-0 '>Pre-Sale is Live</h1>
          <CountdownTimer />
          <div className='mt-3 md:mt-6 relative flex items-center justify-center flex-col w-full' >
            <div className='flex items-center gap-2 w-full justify-center px-4 absolute -bottom-8 bg-white' >
              <MdOutlineContentCopy onClick={() => copyToClipboard('8wsSG2iG2aYVJ9khHPD1JgzzqpQNVABeXjAtC4baSujZ')} className=' flex-shrink-0 cursor-pointer hover:text-blue-500' />
              <p className='truncate font-bold text-[#34AC38]' > <span className='text-black' >Contract Address:</span> 8wsSG2iG2aYVJ9khHPD1JgzzqpQNVABeXjAtC4baSujZ</p>
            </div>
          </div>
        </div>
      </div> */}
      <div id="about" className="relative mx-auto pb-10 px-4 md:px-10 pt-40  w-full  flex justify-center items-center gap-x-[20px]  bg-gradient-to-b from-[#267FC6] to-[#8FC9F7] "      >
        <div className="flex flex-row justify-center items-center  w-[50%] sm:w-[100%] about-item ">
          <img src={aboutHero} alt="frog in coat having drink" className=" absolute about-hero left-[0%] " data-aos="fade-up-right" />
        </div>
        <div className="flex flex-col justify-center items-center  w-[50%] sm:w-[100%] about-item">
          <div className="about-content justify-self-center self-center w-[80%]">
            <h1 className="header font-potta text-white ">About</h1>
            <p className="about-text font-poppins font-bold text-white ">Fueled by pure memetic power, let $SOME show you how to spin the beats!</p>
            <div className="list mt-5">
              <ul className="custom-list">
                <li className=" font-poppins text-white  ">
                  <span className="font-poppins text-white list-head">An Ai Music Bot </span>{" "} <br /> $SOME mixes his tunes using Ai generated meme music that is customised for you.{" "}
                </li>
                <li className=" font-poppins text-white">
                  <span className="font-poppins text-white list-head">Decentralized Platform </span>{" "} <br />$SOME is tired of watching everyone play hot potato with theendless derivative.</li>
              </ul>
            </div>
          </div>
          <img src={homeTopRight} alt="cloud" className="absolute cloud right-[0%]   about-b-cloud" data-aos="fade-up" />
        </div>
      </div>

      <div id="about-extend" className=" relative mx-auto py-10 p-8 md:p-10  w-full  flex justify-center items-center gap-[10px] bg-gradient-to-b from-[#8FC9F7] to-[#267FC6]"      >
        <div className="flex flex-col justify-center items-center   w-[50%] sm:w-[100%] about-item-ex ">
          <div className="about-content justify-self-center self-center  w-[80%]">
            <h1 className="header font-potta text-white ">GET $SOME</h1>
            <p className="get-some-content text-white font-poppins mt-5 ">Using our custom music bot we are able to send custom songs for other memes into other crypto communities without getting banned            </p>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center   w-[50%] sm:w-[100%] about-item-ex ">
          <img src={exAboutHero} alt="purple colored frog dropping mic" className="absolute justify-self-center self-center right-[0%] ex-about-hero" data-aos="fade-up" />
        </div>
      </div>
      <div id="about-extend-blue" className=" relative mx-auto py-10 p-8 md:p-10  w-full  flex justify-center items-center gap-[10px] bg-gradient-to-b from-[#267FC6] to-[#8FC9F7]"      >
        <div className="flex flex-col justify-center items-center   w-[50%] sm:w-[100%] about-item-ex ">
          <img src={blueFrog} alt="blue colored frog making bit" className="absolute justify-self-center self-center blue-frog" data-aos="fade-up" />
          <img src={blueFrogBot} alt="cloud" className="absolute justify-self-center self-center blue-frog-cloud left-[0%] bottom-[0%]" />
        </div>
        <div className="flex flex-col justify-center items-center   w-[50%] sm:w-[100%] about-item-ex ">
          <div className="about-content justify-self-center self-center  w-[80%]">
            <h1 className="header font-potta text-white ">MEME Music</h1>
            <p className="get-some-content text-white font-poppins mt-5 ">Transforming memes into melodies, $SOME is your gateway to sonic innovation!</p>
          </div>
          <img src={purpuleBird} alt="purple bird" className="absolute top-[80%] right-[3%] object" data-value="-8" />
          <img src={smallbluecloud} alt="cloud" className="absolute cloud right-[0%] bottom-[0%]" />
        </div>
      </div>
      <div id="about-extend-green" className=" relative mx-auto py-10 p-8 md:p-10  w-full  flex justify-center items-center gap-[10px] bg-gradient-to-b from-[#8FC9F7] to-[#267FC6]"      >
        <div className="flex flex-col justify-center items-center   w-[50%] sm:w-[100%] about-item-ex ">
          <div className="about-content justify-self-center self-center mb-5  w-[80%]">
            <h1 className="header font-potta text-white ">MEMETIC MASTERY</h1>
            <p className="get-some-content text-white font-poppins mt-5 ">Unlock the potential of memetic energy with $SOME and redefine your musical journey.</p>
          </div>
          <img src={blueBird} alt="" className="absolute cloud left-[2%] bottom-[7%] z-[1] object" data-value="-8" />
          <img src={homebotleft} alt="cloud" className="absolute cloud left-[0%] bottom-[0%] object" data-value="-5" />
        </div>
        <div className="flex flex-col justify-center items-center   w-[50%] sm:w-[100%] about-item-ex ">
          <img src={greenFrog} alt="green  colored frog folding hands" className="absolute justify-self-center self-center green-frog right-[2%]" data-aos="fade-up" />
          <img src={greenFrogBot} alt="cloud" className="absolute justify-self-center self-center green-frog-cloud right-[0%] bottom-[0%]" />
        </div>
      </div>
      <div id="tokenomics" className=" relative mx-auto  px-8 md:px-10  w-full h-auto flex flex-col justify-center items-center   bg-gradient-to-b from-[#267FC6] to-[#8FC9F7]"      >
        <img src={supplySome} alt="Web3 $SOME supply" className=" w-full supplysome " data-aos="fade-up" />
        <img src={tokenomics} alt=" tokenomics " className="  tokenomics   self-center" />
        <img src={homeTopLeft} alt="cloud" className="absolute cloud  left-[0%] top-[58%]" data-aos="fade-up" />
        <img src={smallbluecloud} alt="cloud" className="absolute cloud right-[0%] bottom-[10%] " data-aos="fade-up" />
        <img src={tokenomicsGraph} alt="tokenomics graph" className="mt-[5%]  tokenomics-graph z-[2]" data-aos="fade-up" />
      </div>
      <div id="presale" className="relative mx-auto  px-8 md:px-10  w-full h-auto flex justify-center items-center   bg-gradient-to-b from-[#8FC9F7] to-[#267FC6]">
        <div className="card-wrap flex flex-row justify-center items-center px-8 md:px-10 w-[85%]  " >
          {/* <div className="card w-[50%] bg-white p-[5%] rounded-3xl flex flex-col justify-center items-center" data-aos="fade-up">
            <h2 className="card-head text-black font-potta font-bold text-center">PRESALE DETAILS</h2>
            <img src={cardImg} alt="" className="card-img mt-2 " />
            <h2 className="card-head text-black font-potta font-bold mt-2 text-center">EARLY<span className="timer text-[#34AC38] font-poppins font-bold "> SOMESTERS </span> GET MORE TOKENS            </h2>
          </div> */}
          <div className="card w-full bg-white p-[5%] rounded-3xl" data-aos="fade-up">
            <h2 className="card-head text-black font-potta font-bold mb-3 text-center">GET
              <span className="timer text-[#34AC38] font-poppins font-bold "> $SOME </span>
              IN 4 SIMPLE STEPS
            </h2>
            <p className="card-text text-black font-poppins font-medium text-center mt-3">
              <span className="card-text-head text-black font-poppins font-bold">
                1. PREPARE WALLET:</span>
              Go to the official Phantom wallet website. Download and install it. Securely save your seed phrase (12 words). This step is important to make sure you get your <span className="text-[#34AC38] "> $SOME</span> tokens.
            </p>
            <p className="card-text text-black font-poppins font-medium text-center mt-3">
              <span className=" text-black font-poppins font-bold">
                2. Get SOLANA ($SOL) </span>
              & send them to your Phantom wallet. <span className="text-[#34AC38] "> $SOME </span>is a Solana based coin, so you need some SOL to buy it. And make sure to then send SOL to your Phantom Wallet. Simply use your favorite exchange like:
            </p>
            <div className=" text-black font-poppins font-bold my-[4%] text-center self-center">
              <p className="card-link">Raydium, Dexscreener and more to come</p>
            </div>

            {/*  <div className=" text-black font-poppins font-bold my-[4%] text-center self-center">
                <a className="card-link" href="https://web.archive.org/web/20240511234627/https://www.bybit.com/" target="_blank">  Bybit  </a>
                <a className="card-link" href="https://web.archive.org/web/20240526172119/https://crypto.com/eea" target="_blank"> Crypto.com </a>
                <a className="card-link" href=" https://web.archive.org/web/20240515055317/https://www.coinbase.com/price/solana " target="_blank"> Coinbase </a>
                <a className="card-link" href=" https://web.archive.org/web/20240511210321/https://www.binance.com/en " target="_blank"> Binance </a>
                <a className="card-link" href=" https://web.archive.org/web/20240406144524/https://www.bitpanda.com/en/invest/cryptocurrencies" target="_blank"> BitPanda  </a>
              </div>*/}

            <p className="card-text text-black font-poppins font-medium text-center mt-3">
              <span className=" text-black font-poppins font-bold">
                3. BE FAST AND GET <span className="text-[#34AC38] "> $SOME</span>  : </span>
              Once the pre-sale is LIVE – send your SOL from your Phantom Wallet to the pre-sale address specified on our website. Be aware of scammers – official address will be posted only on the website.
            </p>
          </div>
        </div>
      </div>
      <div id="meme" className="relative mx-auto  px-8 md:px-10  w-full h-auto flex flex-col justify-center items-center   bg-gradient-to-b from-[#267FC6] to-[#8FC9F7]">
        <h1 className="header font-potta text-white ">MEMES</h1>
        <div className="meme-slider " style={{
          '--quantity': '9'
        }}>
          <div className="meme-list">
            <div className="meme-item" style={{ '--position': '1' }}>
              <img src={memeOne} alt="" />
            </div>
            <div className="meme-item " style={{ '--position': '2' }}>
              <img src={memeSix} alt="" />
            </div>
            <div className="meme-item " style={{ '--position': '3' }}>
              <img src={memeFiv} alt="" />
            </div>
            <div className="meme-item " style={{ '--position': '4' }}>
              <img src={memeThr} alt="" />
            </div>
            <div className="meme-item " style={{ '--position': '5' }}>
              <img src={memeTwo} alt="" />
            </div>
            <div className="meme-item " style={{ '--position': '6' }}>
              <img src={memeElv} alt="" />
            </div>
            <div className="meme-item " style={{ '--position': '7' }}>
              <img src={memeFou} alt="" />
            </div>
            <div className="meme-item " style={{ '--position': '8' }}>
              <img src={memeSev} alt="" />
            </div>
            <div className="meme-item " style={{ '--position': '9' }}>
              <img src={memeEig} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div id="FandQ" className="relative mx-auto  px-8 md:px-10  w-full h-auto flex flex-col justify-center items-center   bg-gradient-to-b from-[#8FC9F7] to-[#267FC6]">
        <h1 className="header font-potta text-white mb-10">FAQ</h1>
        <div className="faq-wrap flex flex-row justify-center items-center gap-10 w-[95%]">
          <div className="img-wrap flex flex-col justify-center items-center w-[100%]">
            <img src={footerImg} alt="" className="w-[80%] footer-img" data-aos="fade-up" />
          </div>
          <div className="que-wrap flex flex-col justify-center items-center w-[100%]">
            {faqData.map((item, index) => {
              const isActive = accordionState[index] || false;
              return (
                <div
                  key={index}
                  className="accordian"
                  onClick={() => toggleAccordion(index)}
                >
                  <div className="question flex justify-between">
                    <h4 className="text-black font-poppins que-text">{item.question}</h4>
                    <FontAwesomeIcon
                      icon={isActive ? faCircleMinus : faCirclePlus}
                      style={{ color: '#d9d9d9' }}
                      className="faqicon"
                    />
                  </div>
                  <div
                    className={`answer ${isActive ? 'open' : ''} flex justify-between`}
                  >
                    <p className="text-[#393939] font-poppins ans-text">{item.answer}</p>
                  </div>
                </div>
              );
            })}
          </div>

        </div>
        <h1 className="header faq-bot-head  font-potta text-white self-end ">$SOME</h1>
        <img src={footercloud} alt="cloud" className="absolute w-full top-[90%] footer-cloud z-20" data-aos="fade-up" />
      </div>

      <div id="footer" className={"relative mx-auto  px-8 md:px-10  w-full h-auto flex flex-col justify-center items-center pb-24  bg-[#267FC6]"}>
        <div className="footer-wrap flex justify-center item-center gap-2">
          <img src={footerfrog} alt="green frog" className="w-[13%] foot-frog" />
          <h1 className="header font-potta text-white ">$SOME</h1>
        </div>
        <div className={"footer-overlay flex justify-center item-center absolute w-full "}>
          <div className="footer-content flex flex-row justify-center items-center gap-16 w-[80%] h-full">
            <div className="footer-nav flex flex-row flex-wrap  justify-center md:justify-start gap-8  justify-self-center self-center">
              <Link to="/#about" className="text-white  truncatehover:text-green-400 font-semibold hover:text-shadow-md font-poppins footer-nav-link ">About</Link>
              <Link to="/#presale" className="text-white hover:text-green-400 truncate font-semibold hover:text-shadow-md font-poppins footer-nav-link " > How to buy </Link>
              <Link to="/#tokenomics" className="text-white hover:text-green-400 truncate font-semibold hover:text-shadow-md font-poppins footer-nav-link "> Tokenomics </Link>
              <Link to="/#" className="text-white hover:text-green-400 truncate font-semibold hover:text-shadow-md font-poppins footer-nav-link " >Roadmap</Link>
              <Link to="/privacy-policy" className="text-white hover:text-green-400 truncate font-semibold hover:text-shadow-md font-poppins footer-nav-link ">Privacy Policy</Link>
            </div>
            <h3 className="font-potta text-white footer-head justify-self-center self-center" data-aos="fade-up">$SOME</h3>
            <div className="footer-socials flex flex-row gap-8 justify-self-center self-center" >
              <p className="text-white font-poppins footer-nav-link">Follow us here : </p>
              <a href="https://t.me/SoundOfMeme" target="_blank" rel="noopener noreferrer">

                <FontAwesomeIcon icon={faTelegram} style={{ color: '#ffffff' }} className="social-icon" />
              </a>
              <a href="https://twitter.com/SoundOfMemeSol" target="_blank" rel="noopener noreferrer">

                <FontAwesomeIcon icon={faXTwitter} style={{ color: '#ffffff' }} className="social-icon" />
              </a>
              {/* <a href="https://instagram.com/SoundOfMemeSol" target="_blank" rel="noopener noreferrer">

                <FontAwesomeIcon icon={faInstagram} style={{ color: '#ffffff' }} className="social-icon" />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;

