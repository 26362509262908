import { useRef, useState } from 'react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader } from "../ui/card";
import { TabsContent } from "../ui/tabs";
import { Button } from '../ui/button';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import axios from 'axios';
import { MdDelete } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';


const CreateSongFromCSV = () => {
    const [file, setFile] = useState(null);
    const [toPublish, setToPublish] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const { user } = useSelector(state => state.auth)
    const fileInputRef = useRef()
    const navigate = useNavigate()


    // Function to handle file change
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile && selectedFile.type !== 'text/csv') {
            toast.error('Please select CSV files only.', { id: "csv" });
            clearFileSelection()
        } else {
            setFile(selectedFile);
        }
    };

    const handleSubmit = async () => {
        if (!file) {
            toast.error("Please select a csv file", { id: "csv" })
        }
        const formData = new FormData()
        formData.append('email', user?.email)
        formData.append('file', file)
        formData.append('publish', toPublish)
        try {
            setIsLoading(true)
            const token = localStorage.getItem('token')
            toast.loading('Sending Request', { id: "csv" })
            const { data } = await axios.post("/processcsv", formData, { headers: { Authorization: `Bearer ${token}` } })
            console.log(data);
            toast.success("Request received, you'll receive email once the completion is done.", { id: "csv" })
            clearFileSelection()
            setIsLoading(false)
            navigate("/creations")
        } catch (error) {
            setIsLoading(false)
            console.error(error);
            toast.error(error?.response?.data?.detail || error.message || "Oops! Something went wrong. Please try again.", { id: "csv" });
        }
    };

    const clearFileSelection = () => {
        if (fileInputRef.current) fileInputRef.current.value = '';
        setFile(null);
    };

    return (
        <TabsContent value="csv" >
            <Card className=" bg-transparent border-0" >
                {/* <div className="flex justify-between items-center text-slate-400 mb-1 text-sm" >
                    <p className="font-semibold" >Credits: <span>{user?.credits}</span></p>
                    <p className="font-semibold" >Cost: <span>100</span></p>
                </div> */}
                <CardHeader className="p-0 my-2 mt-4" >
                    <CardDescription className="text-slate-400 text-sm font-medium" >
                        Upload CSV for Song Generation
                    </CardDescription>
                </CardHeader>
                <CardContent className=" flex flex-col gap-4 text-slate-200 p-0 mt-4">
                    <div className="flex items-center gap-2" >
                        <input
                            type="file"
                            accept=".csv" // Ensure only CSV files are selectable in the file dialog
                            onChange={handleFileChange}
                            ref={fileInputRef}
                            className=" disabled:opacity-50 block flex-grow h-10 file:h-10 file:pb-1 file:bg-slate-800 file:border-0 file:text-green-400 w-full text-sm text-slate-300 border border-slate-700/75 rounded file:mr-4 cursor-pointer bg-slate-900 dark:placeholder-gray-400 focus:outline-none focus:ring-1 ring-green-400"
                        />
                        {file && <button onClick={clearFileSelection} type="button" className="bg-red-800 border border-slate-700/75 h-10 w-10 flex items-center justify-center rounded" ><MdDelete size={24} /></button>}
                    </div>
                    <label className="switch flex items-center justify-between border-b  pb-1 border-slate-700 cursor-pointer mt-2 ">
                        <p className="font-semibold text-sm" >Publish</p>
                        <input checked={toPublish} onChange={(e) => setToPublish(e.target.checked)} type="checkbox" />
                        <span className="slider"></span>
                    </label>
                </CardContent>
                <CardFooter className="p-0 mt-6">
                    <Button disabled={isLoading || !file} onClick={handleSubmit} className=' disabled:opacity-50 w-full bg-green-400 text-black hover:bg-green-300 font-semibold' >Submit</Button>
                </CardFooter>
            </Card>
            <p className='text-center mt-4' >Generation will begin, and songs will start showing in your <strong>My Creations</strong> tab as they get generated.</p>
        </TabsContent>);
};

export default CreateSongFromCSV;
