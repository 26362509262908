import {
    LogOut,
    User,
} from "lucide-react"

import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { useSelector } from "react-redux"
import { TbPremiumRights } from "react-icons/tb"
import { Link } from "react-router-dom"
import { IoIosInformationCircleOutline } from "react-icons/io"
import { FaTelegram, FaXTwitter } from "react-icons/fa6"

export function ProfileIcon() {

    const { user } = useSelector(state => state.auth)
    function logOut() {
        localStorage.removeItem('token')
        window.location.replace("/discover")
    }

    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <div className="cursor-pointer text-black font-bold aspect-square bg-green-400 w-10 h-10 flex items-center justify-center rounded-full overflow-hidden " >{user?.profile_url ? <img className="w-full object-cover" src={user?.profile_url} alt="profile" /> : user?.name?.slice(0, 2)}</div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-slate-900 w-auto border-slate-700 text-slate-200 mr-4">
                <DropdownMenuGroup>
                    <DropdownMenuItem className="cursor-pointer focus:bg-green-400">
                        <Link to={"/profile"} className="flex items-center" >
                            <User className="mr-2 h-4 w-4" />
                            <span>Profile</span>
                        </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={logOut} className="cursor-pointer text-white focus:text-black focus:bg-gray-300">
                        <LogOut className="mr-2 h-4 w-4" />
                        <span>Logout</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem className="cursor-pointer text-amber-300 focus:bg-amber-300 focus:text-black">
                        <Link to={"/premium"} className="flex items-center" >
                            <TbPremiumRights className="mr-2 h-4 w-4" />
                            <span>Buy Premium</span>
                        </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem className=" md:hidden cursor-pointer focus:bg-green-400">
                        <a href="https://twitter.com/SoundOfMemeSol" target="_blank" className="flex items-center" >
                            <FaXTwitter className="mr-2 h-4 w-4" />
                            <span>Twitter</span>
                        </a>
                    </DropdownMenuItem>
                    <DropdownMenuItem className=" md:hidden cursor-pointer focus:bg-green-400">
                        <a href="https://t.me/SoundOfMeme" target="_blank" className="flex items-center" >
                            <FaTelegram className="mr-2 h-4 w-4" />
                            <span>Telegram</span>
                        </a>
                    </DropdownMenuItem>
                    <DropdownMenuItem className="cursor-pointer focus:bg-green-400">
                        <Link to={"/about"} className="flex items-center" >
                            <IoIosInformationCircleOutline className="mr-2 h-4 w-4" />
                            <span>About Us</span>
                        </Link>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    )
}
