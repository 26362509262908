import { SET_CLONESONG, SET_CREATEDESC, SET_CUSTOMDESC, SET_CUSTOMLYRICS, SET_CUSTOMTITLE, SET_EXTENDSONG, SET_SELECTEDTAB } from "@/redux/features/music/createSlice";
import { SET_MOBILEPLAYER, SET_PLAYING, SET_PLAYINGFROM, SET_PLAYLOADING, SET_SHOWVIEWSONG, SET_SONG, SET_VIEWSONG, SET_VIEWSONGFROM } from "@/redux/features/music/musicSlice";
import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
// import { BsArrowsExpandVertical } from "react-icons/bs";
import { IoIosShareAlt } from "react-icons/io";
import { IoPlay, IoHeart, IoPlayOutline } from "react-icons/io5";
import { TbDownload } from "react-icons/tb";
import { Oval, Rings } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { BsArrowsExpandVertical } from "react-icons/bs";
import { GiMusicalNotes } from "react-icons/gi";
import { HiOutlineArrowPath } from "react-icons/hi2";

const SongCard = ({ song, playingFrom }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { song: selectedSong, playing, playLoading } = useSelector(state => state.music)
    const { user } = useSelector(state => state.auth)
    const [downloadLoading, setdownloadLoading] = useState(false)

    const handlePlay = () => {
        dispatch(SET_PLAYING(true))
        dispatch(SET_PLAYINGFROM(playingFrom))
        dispatch(SET_SONG(song))
        if (selectedSong?.song_url !== song?.song_url) {
            dispatch(SET_PLAYLOADING(true))
        }
    }

    const handlePause = () => {
        dispatch(SET_PLAYING(false))
    }

    // copy song link
    const copyToClipboard = (url) => {
        navigator.clipboard.writeText(url).then(() => {
            console.log('URL copied to clipboard!');
            toast.success('URL copied to clipboard!', { id: "copyToast" })
        }).catch(err => {
            console.error('Failed to copy:', err);
        });
    };

    const handleShare = async (song) => {
        const url = `https://song.soundofmeme.com/${song?.slug}`;
        const title = 'Check out this song!';
        // const text = song?.song_name + ":";

        if (navigator.share) {
            try {
                await navigator.share({
                    title,
                    // text,
                    url
                });
                console.log('Song shared successfully!');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            copyToClipboard(url)
        }
    };

    const handleMobilePlayer = () => {
        if (song?.song_id !== selectedSong?.song_id) {
            dispatch(SET_VIEWSONG(song))
            dispatch(SET_SHOWVIEWSONG(true))
            dispatch(SET_VIEWSONGFROM(playingFrom))
        } else {
            dispatch(SET_VIEWSONG(null))
            dispatch(SET_SHOWVIEWSONG(false))
            dispatch(SET_VIEWSONGFROM(null))
        }
        dispatch(SET_MOBILEPLAYER(true))
    }

    // download song
    const downloadSong = async (song) => {

        if (!user) {
            return toast.error('Login to download the song')
        }
        const token = window.localStorage.getItem('token')
        try {
            setdownloadLoading(true)
            toast.loading("Starting Download", { id: "download" })
            const response = await axios.get(`/download?song_id=${song?.song_id}`, { headers: { Authorization: `Bearer ${token}` } });
            const signedUrl = response.data.url;
            download(signedUrl);
            setdownloadLoading(false)
            toast.success("Download Started", { id: "download" })
        } catch (error) {
            setdownloadLoading(false)
            console.error('Error fetching signed URL:', error);
            toast.error('Error Occured. Please try again.', { id: "download" });
        }
    };

    function download(url) {
        const a = document.createElement('a');
        a.href = url;
        a.download = `${song?.song_name} - Sound of Meme`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const handleExtend = (e) => {
        e.stopPropagation()
        dispatch(SET_SELECTEDTAB("extend"))
        dispatch(SET_EXTENDSONG({
            song_id: song?.song_id,
            song_name: song?.song_name
        }))
        navigate("/create")
    }
    const handleClone = (e) => {
        e.stopPropagation()
        dispatch(SET_SELECTEDTAB("clone"))
        dispatch(SET_CLONESONG({
            song_id: song?.song_id,
            song_name: song?.song_name
        }))
        navigate("/create")
    }

    const handleRecreate = (song) => {

        if (song?.prompt === null || song.prompt === '' || song.prompt === undefined || song?.prompt?.type === null) {
            toast.error('Feature not available on this song', { id: "recreate" })
            return
        }
        if (song?.prompt?.type === "create" || song?.prompt?.type === "custom") {
            if (song.prompt.type === "create") {
                dispatch(SET_SELECTEDTAB('create'))
                dispatch(SET_CREATEDESC(song.prompt.desc))
                navigate("/create")
            } else if (song.prompt.type === "custom") {
                dispatch(SET_SELECTEDTAB('custom'))
                dispatch(SET_CUSTOMDESC(song.prompt.genre))
                dispatch(SET_CUSTOMTITLE(song.prompt.desc))
                dispatch(SET_CUSTOMLYRICS(song.prompt.lyrics2))
                navigate("/create")
            }
        } else {
            toast.error('Feature not available on this song', { id: "recreate" })
            return
        }
    }

    return (
        <div className="">
            <div className={"w-full overflow-hidden rounded-md relative select-none " + (selectedSong?.song_id === song?.song_id && playing ? " border-2 animate-border shadow-lg shadow-cyan-300 " : selectedSong?.song_id === song?.song_id ? " shadow-lg shadow-cyan-300 border-2 border-green-400/75 " : " border-2 border-green-400/75 ")} >
                <img className="w-full select-none aspect-square" src={song?.image_url} alt='songCover' />
                <div className={"absolute inset-0 w-full h-full  flex items-center justify-center   transition-opacity duration-200" + (selectedSong?.song_id === song?.song_id && playing ? " opacity-100  bg-black/60 backdrop-blur " : selectedSong?.song_id === song?.song_id ? " opacity-100 bg-black/50 backdrop-blur " : " opacity-0 hover:opacity-100 bg-black/50 ")} >
                    {(selectedSong?.song_id === song?.song_id && playLoading) ?
                        <div className="w-full h-full flex items-center justify-center cursor-pointer" >
                            <Oval
                                visible={true}
                                height="40"
                                width="40"
                                strokeWidth={6}
                                strokeWidthSecondary={6}
                                color="#ffffff"
                                ariaLabel="oval-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                            />
                        </div> :
                        (selectedSong?.song_id === song?.song_id && playing) ?
                            <div onClick={handlePause} className="w-full h-full flex items-center justify-center cursor-pointer" >
                                <Rings
                                    visible={true}
                                    height="120"
                                    width="120"
                                    color="#ffffff"
                                    ariaLabel="rings-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                /></div>
                            : <div onClick={handlePlay} className="w-full h-full flex items-center justify-center cursor-pointer" ><IoPlayOutline size={60} /> </div>
                    }
                </div>
            </div>
            <p onClick={handleMobilePlayer} className={"mt-2 font-lato font-semibold truncate capitalize cursor-pointer "} >{song?.song_name}</p>
            <div onClick={handleMobilePlayer} className={"select-none flex gap-2 text-xs mt-1 font-semibold text-slate-400 font-comforta  cursor-pointer "} >
                {song?.tags?.slice(0, 3)?.map((tag, index) => (
                    <span key={index} className="capitalize truncate" >{tag}</span>
                ))}
            </div>
            <div onClick={handleMobilePlayer} className="flex mt-2 justify-between items-center select-none" >
                <div className="flex gap-4" >
                    <div className="flex gap-1 text-xs items-center text-white flex-shrink-0" >
                        <IoPlay size={14} />
                        <span className="text-slate-400">{song?.views}</span>
                    </div>
                    <div className="flex gap-1 text-xs items-center">
                        <IoHeart size={14} />
                        <span className="text-slate-400">{song?.likes}</span>
                    </div>
                    <IoIosShareAlt
                        onClick={(e) => {
                            e.stopPropagation()
                            handleShare(song)
                        }}
                        size={18} className="text-green-400 cursor-pointer flex-shrink-0" />
                    <button
                        type="button"
                        disabled={downloadLoading}
                        onClick={(e) => {
                            e.stopPropagation()
                            downloadSong(song)
                        }}
                        className="disabled:opacity-50 text-green-400 cursor-pointer flex-shrink-0"
                    >
                        <TbDownload size={18} />
                    </button>
                </div>
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <button className="p-1 flex justify-end" > <PiDotsThreeVerticalBold size={22} /> </button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent className="bg-slate-900 w-auto border-slate-700 text-slate-200 mr-4 z-[999] relative">
                        <DropdownMenuGroup>
                            <DropdownMenuItem onClick={handleExtend} className="cursor-pointer p-1.5 focus:text-black text-green-400 focus:bg-green-400 flex items-center gap-2 focus:outline-none rounded">
                                <BsArrowsExpandVertical />
                                <span>Extend</span>
                            </DropdownMenuItem>
                            <DropdownMenuItem onClick={handleClone} className="cursor-pointer p-1.5 focus:text-black text-cyan-400 focus:bg-cyan-400 flex items-center gap-2 focus:outline-none rounded">
                                <GiMusicalNotes />
                                <span>Clone</span>
                            </DropdownMenuItem>
                            {song?.username === user?.username && song?.prompt !== null && song?.prompt?.type !== null && song?.prompt?.type === "create" && <DropdownMenuItem onClick={(e) => {
                                e.stopPropagation()
                                handleRecreate(song)
                            }} className="cursor-pointer p-1.5 focus:text-black text-violet-400 focus:bg-violet-400 flex items-center gap-2 focus:outline-none rounded">
                                <HiOutlineArrowPath />
                                <span>Recreate</span>
                            </DropdownMenuItem>}
                        </DropdownMenuGroup>
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
        </div>
    )
}

export default SongCard


