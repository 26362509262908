// src/components/ErrorBoundary.jsx
import Lottie from "lottie-react";
import React from "react";
import ani from "../../assets/error.json"
import { TbReload } from "react-icons/tb";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null, errorInfo: null };
    }

    // eslint-disable-next-line no-unused-vars
    static getDerivedStateFromError(error) {
        // Update state so the next render shows the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an external service
        console.error("Error caught by ErrorBoundary:", error, errorInfo);
        this.setState({ error, errorInfo });
    }

    render() {
        if (this.state.hasError) {
            // Fallback UI when an error is caught

            const showDetails = import.meta.env.VITE_DEPLOYMENT === 'development'


            const handleClick = () => {
                window.location.reload()
            }

            return (
                <div className={`w-full  bg-gradient-to-br from-slate-950 to-slate-800  text-slate-300`} >

                    <div className=" flex flex-col items-center justify-center gap-4 pt-8 pb-24 px-4 md:px-10 max-w-screen-xl mx-auto min-h-screen overflow-hidden">
                        {!showDetails && <div className="max-w-sm w-full" >
                            <Lottie animationData={ani} />
                        </div>}
                        <h2 className={`text-slate-100 relative -top-8 text-3xl font-semibold font-sans '}`}>Something went wrong...</h2>
                        {!showDetails && <button onClick={handleClick} className="text-green-400 font-medium flex items-center gap-1 relative -top-8" > <TbReload /> Reload</button>}
                        {showDetails && <details style={{ whiteSpace: "pre-wrap" }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo?.componentStack}
                        </details>}
                    </div>
                </div>
            );
        }

        // Render children if no error has occurred
        return this.props.children;
    }
}

export default ErrorBoundary;
