import { SET_PLAYING, SET_PLAYINGFROM, SET_PLAYLOADING, SET_SONG } from "@/redux/features/music/musicSlice"
import { IoPlay } from "react-icons/io5"
import { Oval, Rings } from "react-loader-spinner"
import { useDispatch, useSelector } from "react-redux"

const SongCardSearch = ({ song, playingFrom }) => {

    const dispatch = useDispatch()
    const { song: selectedSong, playing, playLoading } = useSelector(state => state.music)

    const handlePlay = () => {
        dispatch(SET_PLAYING(true))
        dispatch(SET_PLAYINGFROM(playingFrom))
        dispatch(SET_SONG(song))
        if (selectedSong?.song_url !== song?.song_url) {
            dispatch(SET_PLAYLOADING(true))
        }
    }

    const handlePause = () => {
        dispatch(SET_PLAYING(false))
    }

    return (
        <div className={"flex gap-2 h-12 w-full rounded "}
            onClick={(selectedSong?.song_id === song?.song_id && playing) ? handlePause : handlePlay} >
            <div className="flex-shrink-0 w-12 h-12 relative rounded" >
                <img
                    src={song?.image_url} alt="cover"
                    className={" w-full object-cover aspect-square rounded "}
                />
                {selectedSong?.song_id === song?.song_id &&
                    <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center backdrop-blur text-white rounded bg-slate-950/50" >
                        {playLoading ? <Oval
                            visible={true}
                            height="24"
                            width="24"
                            strokeWidth={6}
                            strokeWidthSecondary={6}
                            color="#ffffff"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        /> : (!playLoading && !playing) ?
                            <IoPlay size={32} /> :
                            (!playLoading && playing) ?
                                <Rings
                                    visible={true}
                                    height="40"
                                    width="40"
                                    color="#ffffff"
                                    ariaLabel="rings-loading"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                /> :
                                null}
                    </div>
                }
            </div>
            <div className=" flex flex-col justify-between w-full truncate " >
                <p className={"truncate text-slate-100 font-semibold " + (selectedSong?.song_id === song?.song_id && playing && " animate-text ")} >{song?.song_name}</p>
                <div className=" flex items-center gap-2.5 text-xs truncate text-slate-400" >
                    <p className="truncate" >{song?.username}</p>
                    -
                    <div className="flex items-center gap-1.5 truncate" >
                        {song?.tags?.slice(0, 3)?.map((tag, index) => (
                            <span key={index} className="capitalize" >{tag}</span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SongCardSearch