import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useAuthCheck = (redirectPath = '/discover') => {
    const navigate = useNavigate();
    const { user } = useSelector(state => state.auth);
    const token = localStorage.getItem('token')

    useEffect(() => {
        if (!user || !token) {
            navigate(redirectPath)
        }
    }, [navigate, redirectPath, user, token]);
};

export default useAuthCheck;
