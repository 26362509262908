import { useEffect, useState } from 'react';
import successAni from "../../assets/success.json"
import Lottie from 'lottie-react';

const PaymentSuccess = () => {
    const [seconds, setSeconds] = useState(8);

    useEffect(() => {

        const intervalId = setInterval(() => {
            setSeconds(seconds => seconds - 1);
        }, 1000);

        if (seconds <= 0) {
            window.location.replace("/creations")
            clearInterval(intervalId)
        }
        return () => clearInterval(intervalId);
    }, [seconds]);

    const handleClick = () => {
        window.location.replace("/creations")
    }

    return (
        <div className=" font-poppins flex justify-center items-center h-screen bg-gradient-to-br from-slate-950 via-slate-900 to-slate-800 text-slate-300 px-4">
            <div className="flex flex-col items-center">
                <div className='max-w-xs w-full' >
                    <Lottie animationData={successAni} loop={false} />
                </div>
                <h1 className="text-3xl xs:text-4xl text-center text-slate-50 font-bold mt-4 relative -top-16">Payment Successful!</h1>
                <p className='mt-2 relative -top-16' >You will be redirected in {seconds} seconds.</p>
                <button onClick={handleClick} className="text-green-400 font-medium mt-2 relative -top-16" >Go Home</button>
            </div>
        </div>
    );
};

export default PaymentSuccess;
