import { useEffect, useRef } from "react"
import music from "../..//assets/music.mp3"
import { useSelector } from "react-redux"


const Player = () => {
    const audioRef = useRef()
    const { homeMusic } = useSelector(state => state.music)

    useEffect(() => {
        // Setting loop property directly
        if (audioRef.current) {
            audioRef.current.loop = true;
        }

        if (!homeMusic) {
            audioRef.current.pause()
        } else {
            audioRef.current.play()
        }
    }, [homeMusic])

    return (
        <audio ref={audioRef}>
            <source src={music} type="audio/mpeg" />
            Your browser does not support the audio element.
        </audio>
    )
}

export default Player