import { useEffect, useRef } from "react";

const Lyrics = ({ lyrics }) => {
    const lyricsRef = useRef()

    const formatLyrics = (lyrics) => {
        const lines = lyrics?.split('\n');

        return lines?.map((line, index) => {
            if (line?.trim()?.startsWith('[Verse')
                || line?.trim()?.startsWith('[Chorus')
                || line?.trim()?.startsWith('[Intro')
                || line?.trim()?.startsWith('[Bridge')
                || line?.trim()?.startsWith('[Breakdown')
                || line?.trim()?.startsWith('[ Outro')
                || line?.trim()?.startsWith('Verse')
                || line?.trim()?.startsWith('Chorus')
                || line?.trim()?.startsWith('Bridge')
                || line?.trim()?.startsWith('Outro')
                || line?.trim()?.startsWith('**(')
                || line?.trim()?.startsWith('**')
                || line?.trim()?.startsWith('(')
            ) {
                return <h2 key={index} className="text-xl text-slate-200 font-semibold mt-6 mb-2">{line}</h2>;
            } else {
                return <p key={index} className="mb-2 text-slate-300">{line}</p>;
            }
        });
    };

    // Scroll to the top of the lyrics whenever they change
    useEffect(() => {
        if (lyricsRef.current) {
            lyricsRef.current.scrollTop = 0;  // Scrolls to the top of the container
        }
    }, [lyrics]);

    return (
        <div className="flex items-center justify-center w-full md:w-[300px] lg:w-[400px] border-y border-teal-400/20" >
            <div ref={lyricsRef} className=" flex flex-col  text-center h-[400px] md:h-[500px] overflow-y-auto w-full md:no-scrollbar mt-0 pb-16 md:pb-0 px-6 md:px-0">
                {formatLyrics(lyrics)}
            </div>
        </div>
    )
}

export default Lyrics