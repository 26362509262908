import axios from "axios"
import { useEffect, useRef, useState } from "react"
import toast from "react-hot-toast"
import { GoKebabHorizontal } from "react-icons/go"
import { MdOutlinePublicOff, MdPublic } from "react-icons/md"
import { Drawer, DrawerContent, DrawerTrigger } from "../ui/drawer"
import { FaPlay, FaSpinner, FaStop } from "react-icons/fa6"
import { useDispatch, useSelector } from "react-redux"
import { PLAY_LIVESONG, SET_CREATEDESC, SET_CUSTOMDESC, SET_CUSTOMLYRICS, SET_CUSTOMTITLE, SET_SELECTEDTAB, STOP_LIVESONG } from "@/redux/features/music/createSlice"
import { SET_PLAYING } from "@/redux/features/music/musicSlice"
import { useNavigate } from "react-router-dom"
import { HiOutlineArrowPath } from "react-icons/hi2"

const CreatingSongsLoading = ({ ani, song: creatingSong }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [song, setSong] = useState(creatingSong)
    const [loading, setLoading] = useState(false)
    const [isValidUrl, setIsValidUrl] = useState(false);

    const handlePublish = async (e) => {
        e.stopPropagation()
        const token = window.localStorage.getItem('token')

        try {
            if (song?.publish) {
                setLoading(true)
                toast.loading("Unpublishing", { id: "publish" })
                await axios.post("/private",
                    { song_id: song?.song_id },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                setSong(prevSong => ({
                    ...prevSong,
                    publish: !prevSong.publish
                }));
                toast.success("Unpublished", { id: "publish" })
                setLoading(false)
            } else {
                setLoading(true)
                toast.loading("Publishing", { id: "publish" })
                await axios.post("/public",
                    { song_id: song?.song_id },
                    { headers: { Authorization: `Bearer ${token}` } }
                )
                setSong(prevSong => ({
                    ...prevSong,
                    publish: !prevSong.publish
                }));
                toast.success("Published", { id: "publish" })
                setLoading(false)
            }
        } catch (error) {
            console.log(error);
            setLoading(false)
            toast.error(error?.response?.data?.detail || "Error Occurred", { id: "publish" })

        }
    }

    useEffect(() => {
        // Function to extract item_id from the stream URL
        const checkItemId = () => {
            if (song?.stream === undefined) {
                return false
            }
            const url = new URL(song?.stream);
            const itemId = url.searchParams.get("item_id");
            return itemId && itemId.trim() !== "" && itemId !== "null" && itemId !== "None";
        };

        // Check if item_id is valid
        setIsValidUrl(checkItemId());
    }, [song?.stream]);

    const handleRecreate = (song) => {

        if (song?.prompt === null || song.prompt === '' || song.prompt === undefined) {
            toast.error('Feature not available on this song', { id: "recreate" })
            return
        }
        if (song?.prompt?.type === "create" || song?.prompt?.type === "custom") {
            if (song.prompt.type === "create") {
                dispatch(SET_SELECTEDTAB('create'))
                dispatch(SET_CREATEDESC(song.prompt.desc))
                navigate("/create")
            } else if (song.prompt.type === "custom") {
                dispatch(SET_SELECTEDTAB('custom'))
                dispatch(SET_CUSTOMDESC(song.prompt.genre))
                dispatch(SET_CUSTOMTITLE(song.prompt.desc))
                dispatch(SET_CUSTOMLYRICS(song.prompt.lyrics2))
                navigate("/create")
            }
        } else {
            toast.error('Feature not available on this song', { id: "recreate" })
            return
        }
    }

    // update song
    useEffect(() => {
        setSong(creatingSong)
    }, [creatingSong])


    return (
        <div className="w-full flex items-center gap-2 py-1 border-y border-green-600/50 rounded px-4" >
            <div className="animate-pulse w-full max-w-[80px] h-[80px] object-cover aspect-square rounded " >
                <img src={creatingSong?.image_url ? creatingSong?.image_url : ani} alt="animations" className="w-full p-2 rounded" />
            </div>
            <div className="flex items-center gap-4 flex-grow h-full" >
                <div className=" animate-pulse flex flex-col justify-between gap-2 h-full flex-grow" >
                    {(!creatingSong?.song_name || creatingSong?.song_name === "") ? <div className="h-4 rounded-full mt-1 max-w-sm w-full bg-slate-800" ></div> : <p className={" font-lato font-semibold truncate lg:text-xl capitalize cursor-pointer text-slate-200 "} >{creatingSong?.song_name}</p>}
                    {!creatingSong?.tags ? <div className="flex items-center gap-2" >
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                    </div> : <div className={" select-none flex gap-2 text-xs font-semibold text-slate-400 font-comforta "} >
                        {creatingSong?.tags?.slice(0, 3)?.map((tag, index) => (
                            <span key={index} className="capitalize cursor-default truncate" >{tag}</span>
                        ))}
                    </div>}
                    <div className="flex items-center gap-2 mb-1" >
                        <div className="w-8 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-8 bg-slate-800 rounded-full h-3" ></div>
                        <div className="w-3 bg-slate-800 rounded-full h-3" ></div>
                    </div>
                </div>
                <div className="flex items-center justify-end gap-2 lg:gap-4 flex-grow " >
                    {isValidUrl ?
                        <AudioPlayer streamUrl={song?.stream} />
                        : <div className="h-9 w-9 bg-slate-800 animate-pulse rounded-full" ></div>
                    }
                    {song?.publish !== undefined ? <button disabled={loading} onClick={handlePublish} type="button" className="hidden sm:flex animate-none disabled:opacity-50 h-8 text-xs lg:text-sm items-center gap-1 text-blue-400 hover:bg-blue-400/10 font-semibold border border-blue-400/50 px-2 py-2 rounded "  > {!song?.publish ? <><MdPublic size={18} /> Publish</> : <><MdOutlinePublicOff size={18} />Unpublish</>}</button> : <div className="animate-pulse hidden sm:block h-8 w-[70px] lg:w-20 bg-slate-800 rounded" ></div>}
                    {(song?.prompt?.type === "create") ? <button onClick={() => handleRecreate(creatingSong)} type="button" className="hidden sm:flex animate-none disabled:opacity-50 h-8 text-xs lg:text-sm items-center gap-1 text-purple-400 hover:bg-purple-400/10 font-semibold border border-purple-400/50 px-2 py-2 rounded "  ><HiOutlineArrowPath size={18} />Recreate</button> : <div className="animate-pulse hidden sm:block h-8 w-[70px] lg:w-20 bg-slate-800 rounded" ></div>}
                    <div className="animate-pulse hidden xl:block h-8 w-[70px] xl:w-20 bg-slate-800 rounded" ></div>
                    <div className="animate-pulse hidden xl:block h-8 w-[70px] xlg:w-20 bg-slate-800 rounded" ></div>
                    <div className="animate-pulse hidden sm:block h-8 w-9 bg-slate-800 rounded" ></div>
                    <div className=" animate-pulse hidden sm:block h-8 w-9 bg-slate-800 md:hidden lg:block rounded" ></div>
                    <GoKebabHorizontal className={`ml-4 xl:hidden ${song?.publish !== undefined ? " hidden sm:block sm:opacity-50 " : " opacity-50 animate-pulse "} `} size={24} />
                    {song?.publish !== undefined && <div className="sm:hidden flex items-center " >
                        <Drawer>
                            <DrawerTrigger>
                                <GoKebabHorizontal size={40} className="pl-4" />
                            </DrawerTrigger>
                            <DrawerContent className=" font-poppins bg-gradient-to-br from-slate-950 to-slate-900 text-slate-300 pb-8 border-green-400/50" >
                                <div className="flex items-center justify-between  gap-2 my-4 px-4 animate-pulse" >
                                    <div className="flex-grow truncate " >
                                        {(!creatingSong?.song_name || creatingSong?.song_name === "") ? <p className={" h-4 my-2 bg-slate-800 w-2/3 rounded-full "} ></p> : <p className={" font-lato font-semibold truncate lg:text-xl capitalize cursor-pointer text-slate-200 "} >{creatingSong?.song_name}</p>}
                                        {!creatingSong?.tags ? <div className={"select-none flex gap-2 mt-3"} >
                                            <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                                            <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                                            <div className="w-10 bg-slate-800 rounded-full h-3" ></div>
                                        </div> : <div className={" select-none flex gap-2 text-xs font-semibold text-slate-400 font-comforta "} >
                                            {creatingSong?.tags?.slice(0, 3)?.map((tag, index) => (
                                                <span key={index} className="capitalize cursor-default truncate" >{tag}</span>
                                            ))}
                                        </div>}
                                    </div>
                                    {!creatingSong?.image_url ? <div className="h-12 w-12 bg-slate-800 rounded flex-shrink" ></div> : <img src={creatingSong?.image_url} alt="cover" className="w-12 h-12 rounded-lg" />}
                                </div>
                                <div className="flex flex-col gap-2" >
                                    {song?.publish !== undefined ? <button disabled={loading} onClick={handlePublish} type="button" className=" disabled:opacity-50 py-2 px-4 text-lg text-blue-400 hover:bg-blue-400/10 w-full flex items-center gap-2 font-medium "  > {!song?.publish ? <><MdPublic size={24} /> Publish</> : <><MdOutlinePublicOff size={24} />Unpublish</>}</button> : <div className="h-9 my-1 bg-slate-800 animate-pulse w-full" ></div>}
                                    {(song?.prompt?.type === "create") ? <button onClick={() => handleRecreate(creatingSong)} type="button" className=" disabled:opacity-50 py-2 px-4 text-lg text-purple-400 hover:bg-purple-400/10 w-full flex items-center gap-2 font-medium "  > <HiOutlineArrowPath size={24} /> Recreate </button> : <div className="h-9 my-1 bg-slate-800 animate-pulse w-full" ></div>}
                                    <div className="h-9 my-1 bg-slate-800 animate-pulse w-full" ></div>
                                    <div className="h-9 my-1 bg-slate-800 animate-pulse w-full" ></div>
                                    <div className="h-9 my-1 bg-slate-800 animate-pulse w-full" ></div>
                                    {/* <div onClick={(e) => {
                                        e.stopPropagation()
                                        handleShare(song)
                                    }} className=" text-lg py-2 px-4 text-amber-300 hover:bg-amber-300/10 w-full cursor-pointer flex gap-2 items-center select-none" >
                                        <IoIosShareAlt size={24} className="cursor-pointer flex-shrink-0" />
                                        Share
                                    </div> */}
                                    {/* <button onClick={handleExtend} type="button" className=" py-2 px-4 text-lg text-green-400 hover:bg-green-400/10 w-full flex items-center gap-2 font-medium  "  > <BsArrowsExpandVertical size={24} /> Extend</button> */}
                                    {/* <button onClick={handleClone} type="button" className=" py-2 px-4 text-lg text-teal-400 hover:bg-teal-400/10 w-full flex items-center gap-2 font-medium  "  > <GiMusicalNotes size={24} /> Clone</button> */}
                                    {/* <button disabled={loading} onClick={handlePublish} type="button" className=" disabled:opacity-50 py-2 px-4 text-lg text-blue-400 hover:bg-blue-400/10 w-full flex items-center gap-2 font-medium "  > {!userSong?.publish ? <><MdPublic size={24} /> Publish</> : <><MdOutlinePublicOff size={24} />Unpublish</>}</button> */}
                                    {/* <button disabled={downloadLoading} onClick={() => downloadSong(song)} type="button" className=" disabled:opacity-50 py-2 px-4 text-lg text-cyan-400 hover:bg-cyan-400/10 w-full flex items-center gap-2 font-medium "  ><TbDownload size={24} /> Download</button> */}
                                </div>
                            </DrawerContent>
                        </Drawer>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default CreatingSongsLoading

export const AudioPlayer = ({ streamUrl }) => {

    const dispatch = useDispatch()
    const audioRef = useRef(null);
    const { liveSong } = useSelector(state => state.create)
    const [isBuffering, setIsBuffering] = useState(false);

    const togglePlayPause = () => {
        if (liveSong?.isPlaying && liveSong?.url === streamUrl) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0
            dispatch(STOP_LIVESONG())
        } else {
            dispatch(SET_PLAYING(false))
            dispatch(PLAY_LIVESONG({ url: streamUrl, isPlaying: true }))
            audioRef.current.play();
        }
    };

    // Handle buffering state when the song is playing
    useEffect(() => {
        const audio = audioRef.current;

        // Event listeners for buffering and canplay states
        const handleBuffering = () => {
            setIsBuffering(true); // Set buffering to true
        };

        const handleCanPlay = () => {
            setIsBuffering(false); // Set buffering to false once it's ready to play
        };

        const handlePlaying = () => {
            setIsBuffering(false); // Stop buffering when the audio starts playing
        };

        if (audio) {
            audio.addEventListener('waiting', handleBuffering);
            audio.addEventListener('canplay', handleCanPlay);
            audio.addEventListener('playing', handlePlaying);
        }

        // Clean up event listeners on component unmount
        return () => {
            if (audio) {
                audio.removeEventListener('waiting', handleBuffering);
                audio.removeEventListener('canplay', handleCanPlay);
                audio.removeEventListener('playing', handlePlaying);
            }
        };
    }, []);

    useEffect(() => {
        if (liveSong?.url !== streamUrl || liveSong === undefined || liveSong === null) {
            audioRef.current.pause()
            audioRef.current.currentTime = 0
        }
    }, [liveSong, streamUrl])

    return (
        <div className="flex items-center">
            <audio ref={audioRef}>
                <source src={streamUrl} type="audio/mpeg" />
                Your browser does not support the audio tag.
            </audio>
            <button
                onClick={togglePlayPause}
                className={`rounded-full focus:outline-none focus:shadow-outline border-2 border-green-400 aspect-square overflow-hidden p-1.5 flex items-center justify-center ${(liveSong?.isPlaying && liveSong?.url === streamUrl) ? ' animate-border2 ' : '  '}`}
            >
                {isBuffering ? (
                    <FaSpinner size={18} className="text-gray-500 animate-spin" /> // Loading spinner
                ) : (liveSong?.isPlaying && liveSong?.url === streamUrl) ? (
                    <FaStop size={18} className="text-red-500" />
                ) : (
                    <FaPlay size={18} className="text-green-500 ml-[2px]" />
                )}
            </button>
        </div>
    );
};