import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardFooter,
} from "@/components/ui/card"
import {
    TabsContent,
} from "@/components/ui/tabs"
import { useForm } from "react-hook-form"
import { useDispatch } from "react-redux"
import { getUserDetails, loginUser } from "@/redux/features/auth/authSlice"
import GoogleLoginButton from "@/components/googleLoginButton/GoogleLoginButton"
import { useNavigate } from "react-router-dom"

const LoginForm = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { handleSubmit, register, reset, formState: { errors } } = useForm()

    // login
    const handleLogin = async (data) => {
        const { email, password } = data
        const formData = {
            email,
            password
        }
        await dispatch(loginUser(formData)).unwrap()
        await dispatch(getUserDetails()).unwrap()
        navigate("/creations")
        reset()
    }

    return (
        <TabsContent value="login" className="mt-6">
            <Card className=" bg-transparent p-0 border-0 " >
                <form onSubmit={handleSubmit(handleLogin)} >
                    <CardContent className="space-y-8  p-0">
                        <div className="flex flex-col gap-3 relative">
                            <input type='text'
                                {...register('email', {
                                    required: 'Email or Username is required',
                                })}
                                placeholder="Email or Username"
                                className='bg-slate-900 text-white p-2 rounded placeholder:text-sm placeholder:text-slate-500 border-slate-700/75 border'
                            />
                            {errors.email && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.email.message}</p>}
                        </div>
                        <div className="flex flex-col gap-3 relative">
                            <input
                                type="password"
                                autoComplete="off"
                                placeholder="Password"
                                {...register('password', {
                                    required: 'Password is required',
                                    minLength: {
                                        value: 8,
                                        message: 'Password must have at least 8 characters'
                                    }
                                })}
                                className='bg-slate-900 border border-slate-700/75 text-white p-2 placeholder:text-sm placeholder:text-slate-500 rounded'
                            />
                            {errors.password && <p className=' absolute -bottom-5 right-1 text-xs text-green-400 font-medium  ' >{errors.password.message}</p>}
                        </div>
                    </CardContent>
                    <CardFooter className="p-0 mt-8" >
                        <Button type="submit" className="w-full bg-green-400 hover:bg-green-500 text-black " >Login</Button>
                    </CardFooter>
                </form>
                <div className='h-px my-4 bg-slate-600' ></div>
                <div className='flex items-center justify-center' >
                    <GoogleLoginButton />
                </div>
            </Card>
        </TabsContent>
    )
}

export default LoginForm