import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { STOP_LIVESONG } from '@/redux/features/music/createSlice';

const RouteChangeListener = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        // Call the STOP_LIVESONG action whenever the route changes
        dispatch(STOP_LIVESONG());
    }, [location, dispatch]);

    return null; // This component doesn't render anything
};

export default RouteChangeListener;
