import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom"
import Home from "./pages/home/Home"
import WenWhite from "./pages/wenWhite/WenWhite"
import { Toaster } from "react-hot-toast"
import Player from "./components/player/Player"
import Sidebar from "./components/sidebar/Sidebar"
import MyCreations from "./pages/myCreations/MyCreations"
import Discover from "./pages/discover/Discover"
import LikedSongs from "./pages/likedSongs/LikedSongs"
import Song from "./pages/song/Song"
import axios from "axios"
import { useEffect, useState } from "react"
import MusicPlayer from "./components/musicPlayer/MusicPlayer"
import { useDispatch } from "react-redux"
import { getUserDetails } from "./redux/features/auth/authSlice"
import Create from "./pages/create/Create"
import Login from "./pages/login/Login"
import Search from "./pages/search/Search"
import Subscription from "./pages/subscription/Subscription"
import PaymentSuccess from "./pages/subscription/PaymentSuccess"
import PaymentFailure from "./pages/subscription/PaymentFailure"
import Profile from "./pages/profile/Profile"
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy"
import DiscoverRedirect from "./pages/discover/DiscoverRedirect"
import RouteChangeListener from "./components/routeChangeListener/RouteChangeListner"
// import Playlist from './pages/playlist/Playlist';

function App() {

  axios.defaults.baseURL = "https://testapi.soundofmeme.com"

  const dispatch = useDispatch()
  const [playerAllowed, setPlayerAllowed] = useState(false)

  // Custom hook to handle route changes
  function useRouteChange() {
    const location = useLocation();
    useEffect(() => {
      // Define routes where music should play
      const musicRoutes = ['/', '/creations', "/discover", "/liked", "/create", "/login", "/search", "/premium", "/profile", "/playlist"];
      setPlayerAllowed(musicRoutes.includes(location.pathname));
    }, [location]);
  }
  // Component that manages music playing
  function ManageMusic() {
    useRouteChange();
    return playerAllowed ? <MusicPlayer bgPlayer={true} /> : null;
  }

  useEffect(() => {
    dispatch(getUserDetails())
  }, [dispatch])

  return (
    <BrowserRouter>
      <Player />
      <RouteChangeListener />
      <Routes>
        <Route path="/discover" element={<DiscoverRedirect />} />
        <Route path="/about" element={<Home />} />
        <Route path="/paper" element={<WenWhite />} />
        <Route path="/creations" element={
          <Sidebar>
            <MyCreations />
          </Sidebar>
        } />
        <Route path="/" element={
          <Sidebar>
            <Discover />
          </Sidebar>
        } />
        <Route path="/liked" element={
          <Sidebar>
            <LikedSongs />
          </Sidebar>
        } />
        <Route path="/create" element={
          <Sidebar>
            <Create />
          </Sidebar>
        } />
        <Route path="/login" element={
          <Sidebar>
            <Login />
          </Sidebar>
        } />
        <Route path="/search" element={
          <Sidebar>
            <Search />
          </Sidebar>
        } />
        <Route path="/premium" element={
          <Sidebar>
            <Subscription />
          </Sidebar>
        } />
        <Route path="/profile" element={
          <Sidebar>
            <Profile />
          </Sidebar>
        } />
        {/* <Route path="/playlist" element={
          <Sidebar>
            <Playlist />
          </Sidebar>
        } /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/premium/success" element={
          <PaymentSuccess />
        } />
        <Route path="/premium/failure" element={
          <PaymentFailure />
        } />
        <Route path="/song/:id" element={<Song />} />
      </Routes>
      <Toaster
        toastOptions={{
          className: "toast"
        }}
      />
      <ManageMusic />
    </BrowserRouter>
  )
}

export default App
