import { useEffect, useRef, useState } from "react"
import MusicPlayer from "@/components/musicPlayer/MusicPlayer"
import { useDispatch, useSelector } from "react-redux"
import { SET_PLAYING, SET_SONG } from "@/redux/features/music/musicSlice"
import ani from "../../assets/soundwaves.json"
import Lottie from "lottie-react"
import { useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import toast from "react-hot-toast"


const Song = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { playing } = useSelector(state => state.music)
    const { id } = useParams()
    const [song, setSong] = useState(null)

    const animateRef = useRef()

    const handleClick = () => {
          navigate("/discover")
          dispatch(SET_SONG(null))
          dispatch(SET_PLAYING(false))
    }

    useEffect(() => {
        async function getSong() {
            const token = localStorage.getItem('token')
            try {
                const { data } = await axios.get(`/getsongbyid?id=${id}`, { headers: { Authorization: `Bearer ${token}` } })
                setSong(data)
                dispatch(SET_SONG(data))
            } catch (error) {
                console.log(error);
                toast.error("Error getting song")
            }
        }
        getSong()
    }, [id, dispatch])

    useEffect(() => {
        if (playing) {
            animateRef.current.play()
        } else {
            animateRef.current.pause()
        }
    }, [playing])

    useEffect(() => {
        if (song) {
            document.title = `${song?.song_name} - Sound of Meme`;
            document.querySelector('meta[property="og:title"]').setAttribute("content", `${song?.song_name} - Sound of Meme`);
            document.querySelector('meta[property="og:image"]').setAttribute("content", song?.image_url);
            document.querySelector('meta[property="og:url"]').setAttribute("content", `https://soundofmeme.com/song/${id}`);
            document.querySelector('meta[name="twitter:title"]').setAttribute("content", `${song?.song_name} - Sound of Meme`);
            document.querySelector('meta[name="twitter:image"]').setAttribute("content", song?.image_url);
        }
    }, [song, id]);

    return (
        <div style={{ height: "100dvh" }} className="flex items-center justify-center w-full relative bg-slate-950/75" >
            <div className="absolute top-16 left-0 w-full flex items-center justify-center" >
                  <button onClick={handleClick} type="button" className="font-potta text-4xl bg-gradient-to-r from-teal-400 to-green-400 bg-clip-text text-transparent" >SOUND OF MEME</button>
            </div>
            <img className=" select-none fixed top-0 left-0 w-full h-full object-cover -z-10 blur aspect-square" src={song?.image_url} />
            <div className="w-full mx-auto flex flex-col items-center" >
                <div className="relative max-w-xs w-full aspect-square flex items-center justify-center" >
                    <img className="max-w-[230px] relative z-20 rounded-full w-full aspect-square object-cover " src={song?.image_url} />
                    <div className="absolute text-yellow-400 top-0 left-0 w-full z-10" ><Lottie lottieRef={animateRef} animationData={ani} /></div>
                </div>
                <MusicPlayer />
            </div>
        </div>
    )
}

export default Song